import { useEffect, useState } from "react";

export const useGetData = <T>(apiCall: () => Promise<T>) => {
  const [data, setData] = useState<T>();
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await apiCall();
        setData(res);
        setLoading(false);
        setError("");
      } catch (error: any) {
        setError(error.message);
      }
    })();
  }, [refresh]);

  return {
    data,
    error,
    loading,
    reset: () => setRefresh(!refresh),
  };
};
