import React, { FC, memo } from "react";
import { StyledDialog } from "./Dialog.style";

interface Props {
  content: JSX.Element;
  trigger?: JSX.Element;
  open: boolean;
}

export const Dialog: FC<Props> = memo((props) => <StyledDialog {...props} />);
