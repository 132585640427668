import { UPDATE_API_CONFIG, UPDATE_CONFIG, UpdateAPIAction } from "./types";
import { AES, enc, pad } from "crypto-js";
import CONFIGS from "core/utils/configs";
import { setCookie } from "core/utils/cookies";
import CompanyModel from "core/models/Company";
import { getSubdomain } from "core/utils/helpers";

/**
 * update config
 *
 * @param front
 * @param publicKey
 * @param privateKey
 * @param jwt
 * @returns
 */
export const updateConfig = (company: CompanyModel) => {
  try {
    const key = enc.Hex.parse(CONFIGS.AES_KEY);
    const iv = enc.Hex.parse(CONFIGS.AES_IV);
    const jwtDecrypt = AES.decrypt(company.jwt, key, {
      iv,
      padding: pad.ZeroPadding,
    }).toString(enc.Utf8);
    const publicKeyDecrypt = AES.decrypt(company.publicKey, key, {
      iv,
      padding: pad.ZeroPadding,
    }).toString(enc.Utf8);
    const privateKeyDecrypt = AES.decrypt(company.privateKey, key, {
      iv,
      padding: pad.ZeroPadding,
    }).toString(enc.Utf8);

    // Add subdomain to cookie with expiration date of 1 day
    const subdomainCookies = btoa(
      JSON.stringify({ name: company.name, custom: company.custom })
    );
    const domain = getSubdomain(company.name, company.custom);
    const hostname = new URL(domain).hostname;
    setCookie(
      "riseup-subdomain",
      subdomainCookies,
      new Date(Date.now() + 86400000),
      hostname
    );

    return {
      type: UPDATE_CONFIG,
      payload: {
        jwt: jwtDecrypt,
        companyId: company.id,
        front: domain,
        publicKey: publicKeyDecrypt,
        privateKey: privateKeyDecrypt,
        welcomeMessage: company.welcome_message,
        firstRunMessage: company.first_run_message,
        token: company.token,
      },
    };
  } catch (error) {}
};
export const updateApiUrl = (api_end_point: string): UpdateAPIAction => ({
  type: UPDATE_API_CONFIG,
  payload: {
    api_end_point,
  },
});
