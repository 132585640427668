import React from "react";
import {
  CardHeader,
  Card,
  Flex,
  CardBody,
  CardFooter,
  Text,
  Button,
  Box,
} from "@fluentui/react-northstar";
import { Image } from "../Image/Image";
import TrainingModel from "core/models/Training";
import { formatCourseCardDuration, getTrainingType } from "core/utils/helpers";
import { useTranslation } from "react-i18next";
import { ReactComponent as DurationIcon } from "assets/images/svg/duration.svg";
import {
  AdditionalInfo,
  AdditionalInfoContainer,
  AdditionalInfoTitle,
  NotAvailableContainer,
  DataContainer,
  StepIconContainer,
  BoxOverlay,
  LockIcon,
} from "./Card.style";
import { StepIcon } from "components/StepIcon/StepIcon";
import styles from "./Card.module.css";
import defaultTrainingIcon from "assets/images/png/training.png";
interface Props {
  course: TrainingModel;
  action?: string;
  handleClick: () => void;
}

export const FuiCard: React.FC<Props> = ({
  course: {
    img,
    title,
    catalogexternal,
    catalogname,
    moduletype,
    progress,
    eduduration,
    results,
    next_step,
    available = true,
    paths,
  },
  action = "",
  handleClick,
}) => {
  const { t } = useTranslation();
  const cardStyles = {
    border: "none",
  };
  const cardBottomStyles = {
    marginBottom: "1rem",
  };
  const subTitle =
    catalogexternal && catalogname
      ? catalogname
      : t(getTrainingType(moduletype));

  const duration = formatCourseCardDuration(eduduration);
  const learningPath = paths?.[0];

  return (
    <Card style={cardStyles}>
      {!!learningPath && (
        <div className={styles.learningPathCardContainer}>
          <div className={styles.learningPathInnerCardContainer}>
            <Image
              errorSrc={defaultTrainingIcon}
              src={learningPath.img || defaultTrainingIcon}
              className={styles.learningPathImage}
              alt={learningPath.name}
            />
            <div className={styles.learningPathTitle}>
              <Text
                className={styles.smallTextRegular}
                content={t("Learning path")}
              />
              <Text
                className={styles.regularTextSemiBold}
                content={learningPath.name}
              />
            </div>
          </div>
        </div>
      )}
      {!available && (
        <NotAvailableContainer>
          <BoxOverlay />
          <LockIcon />
        </NotAvailableContainer>
      )}
      <CardHeader>
        <img src={img} alt={title} />
      </CardHeader>
      <CardBody>
        <Flex className="card_titles_container">
          <Flex className="card_title_container">
            <Text className="card card_title card_title_color">{title}</Text>
          </Flex>
          <Text className="card card_sub_title">{subTitle}</Text>
        </Flex>
        <Flex className="progress_container">
          <Text className="progress" weight="bold" content={`${progress}%`} />
          <Box className="progress progress_bar_container">
            <Box
              className="progress progress_bar"
              style={{
                width: `${progress}%`,
              }}
            />
          </Box>
        </Flex>
        <Flex className="duration_container">
          <DurationIcon />
          <Text className="duration">{duration}</Text>
        </Flex>
        <AdditionalInfoContainer>
          {next_step && (
            <AdditionalInfoTitle>{t("next step")}</AdditionalInfoTitle>
          )}
          {results && (
            <AdditionalInfoTitle>{t("My results")}</AdditionalInfoTitle>
          )}
          <AdditionalInfo>
            {results?.totalScore && (
              <DataContainer>
                <Text>{t("Score")}</Text>
                <Text>{results.totalScore} </Text>
              </DataContainer>
            )}
            {results?.totalTrainingTime && (
              <DataContainer>
                <Text>{t("Time spent")} </Text>
                <Text>{results.totalTrainingTime} </Text>
              </DataContainer>
            )}
            {next_step && (
              <Flex vAlign="center">
                <StepIconContainer>
                  <StepIcon stepType={next_step.stepType} />
                </StepIconContainer>
                <Text align="center">{next_step.title}</Text>
              </Flex>
            )}
          </AdditionalInfo>
        </AdditionalInfoContainer>
      </CardBody>
      <CardFooter style={cardBottomStyles}>
        <Flex className="card_footer_container">
          <Button className="footer_button" onClick={handleClick}>
            {action}
          </Button>
        </Flex>
      </CardFooter>
    </Card>
  );
};
