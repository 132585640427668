import { getCookies, sendAsyncRequest, setCookies } from "core/utils/helpers";
import CONSTANTS from "core/utils/constants";
import URLS from "core/utils/urls";

/**
 * get api token
 * @param publicKey
 * @param privateKey
 * @returns
 */
const authentication = (
  publicKey: string,
  privateKey: string
): Promise<{ [key: string]: string }> => {
  // transform to base 64
  const authorization = window.btoa(`${publicKey}:${privateKey}`);
  // check if token already saved in cookies , if it is the case then return token from cookies
  const cookiesData = getCookies(`${CONSTANTS.COOKIES.TOKEN}/${authorization}`);
  if (cookiesData !== null) return JSON.parse(cookiesData);
  // set grant type
  const data = new URLSearchParams();
  data.append("grant_type", "client_credentials");
  return sendAsyncRequest(
    "post",
    URLS.AUTHENTICATION,
    `Basic ${authorization}`,
    undefined,
    data,
    "application/x-www-form-urlencoded"
  ).then((response) => {
    // get expiration date
    const expires = new Date(
      new Date().getTime() + CONSTANTS.COOKIES_DURATION / 2
    );
    // save data in cookies
    setCookies(
      `${CONSTANTS.COOKIES.TOKEN}/${authorization}`,
      expires,
      JSON.stringify(response.data)
    );
    return response.data;
  });
};

export const auth = { authentication };
