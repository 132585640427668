import React, { useCallback, useState } from "react";
import styles from "./Image.module.css";

interface ImagePropsInterface {
  src: string;
  errorSrc: string;
  className?: string;
  alt?: string;
}

export const Image = React.memo((props: ImagePropsInterface) => {
  const {
    className, // style class
    errorSrc, // error image to show when error happen when displaying real image
    alt, // image alt
  } = props;
  // image to display
  const [src, setSrc] = useState<string>(props.src);
  // handle error when displaying the image
  const onError = useCallback(() => {
    setSrc(errorSrc);
  }, [errorSrc]);
  return (
    <div className={className}>
      <img
        onError={onError}
        src={src}
        className={className ?? styles.img}
        alt={alt}
      />
    </div>
  );
});
