import React, { FC, memo } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { Container, Price } from "./RangeSelector.style";
import CONSTANTS from "core/utils/constants";
import { Flex } from "@fluentui/react-northstar";
import { PriceRange } from "shared/types";

interface Props {
  handlePriceRange: (range: PriceRange) => void;
  priceRange?: PriceRange;
}

export const RangeSelect: FC<Props> = memo(
  ({ priceRange: range, handlePriceRange }) => {
    const [min, max] = range ?? [
      CONSTANTS.COURSE_PRICE_RANGE.MIN,
      CONSTANTS.COURSE_PRICE_RANGE.MAX,
    ];
    return (
      <Container column>
        <Flex column style={{ width: "inherit" }}>
          <RangeSlider
            min={CONSTANTS.COURSE_PRICE_RANGE.MIN}
            max={CONSTANTS.COURSE_PRICE_RANGE.MAX}
            defaultValue={[
              CONSTANTS.COURSE_PRICE_RANGE.MIN,
              CONSTANTS.COURSE_PRICE_RANGE.MAX,
            ]}
            value={[min, max]}
            onInput={handlePriceRange}
          />
        </Flex>
        <Flex
          space="between"
          style={{ width: "100%" }}
          padding="padding.medium"
        >
          <Price value={min.toString()} disabled />
          <Price value={max} disabled />
        </Flex>
      </Container>
    );
  }
);
