import { checkTermsOfUse, handleTermsOfUse } from "apis/termsOfUse";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TOS } from "shared/interfaces";
import { RootState } from "store/rootReducer";
import { useNavigate } from "react-router-dom";
import PATHS from "core/utils/paths";
import { useLocation } from "react-router-dom";

export const useTermsOfUse = () => {
  const [termsOfUse, setTermsOfUse] = useState<TOS>();
  const [userAcceptedTheTerms, setUserAcceptedTheTerms] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [reset, setReset] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const userId = useSelector((state: RootState) => state.auth.auth.id);

  useEffect(() => {
    (async () => {
      try {
        const data = await checkTermsOfUse(userId);
        setTermsOfUse(data);
        setTimeout(() => setLoading(false), 1000);
      } catch (error) {
        setLoading(false);
        //@ts-ignore
        setError(error.message || "Something went wrong");
      }
    })();
  }, [userId, reset]);

  const handle = async (accept: boolean) => {
    try {
      const data = await handleTermsOfUse(userId, accept);
      if (!data.userAcceptedTheTerms) {
        if (pathname !== PATHS.NOT_FOUND) {
          navigate(PATHS.NOT_FOUND);
        }
      } else {
        setUserAcceptedTheTerms(true);
        navigate(PATHS.DASHBOARD);
      }
    } catch (error) {}
  };

  return {
    handle,
    userAcceptedTheTerms,
    termsOfUse,
    loading,
    error,
    tryAgain: () => setReset(!reset),
  };
};
