import { AnalyticsModel, EventName } from "core/models/Analytics";

export function initAnalytics(analytics?: AnalyticsModel): void {
  // Initialize Pendo with visitor and account data
  if (!analytics) {
    pendo?.initialize({
      visitor: {
        id: "NO_USER_INFO",
      },
      account: {
        id: "NO_USER_INFO",
      },
    });
    return;
  }

  pendo?.initialize(analytics);
}

export function logAnalyticsEvent(
  eventName: EventName,
  properties: Record<string, any> = {}
): void {
  if (pendo) {
    pendo.track(eventName, properties);
  } else {
    console.warn("Pendo has not been initialized yet");
  }
}
