import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "./Dropdown.style";
import { ReactComponent as DownIcon } from "assets/images/svg/down.svg";
import { DropDownItem } from "shared/interfaces";
import { FilterData } from "core/models";

interface Props {
  search?: boolean;
  multiple?: boolean;
  options: FilterData[];
  value?: DropDownItem[];
  handleChange: (value: DropDownItem[]) => void;
}

export const FuiDropdown: React.FC<Props> = ({
  search = false,
  multiple = false,
  options,
  value,
  handleChange,
}) => {
  const { t } = useTranslation();
  const onChange = useCallback(
    (_: unknown, { value }: { value: DropDownItem[] }) => handleChange(value),
    [handleChange]
  );
  const items = options.map((option) => ({
    key: option.id,
    header: option.name,
  }));

  return (
    <Dropdown
      search={search}
      multiple={multiple}
      items={items}
      value={value}
      onChange={onChange}
      placeholder={t("Select an option")}
      noResultsMessage={t("No results found")}
      toggleIndicator={<DownIcon />}
    />
  );
};
