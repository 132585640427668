import React, { FC, memo } from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import { useTermsOfUse } from "./hooks/useTermsOfUse";
import {
  InfoMessageContainer,
  StyledDialog,
  TitleContainer,
  TosContainer,
} from "./TermsOfUse.style";
import { useTranslation } from "react-i18next";
import { Loader, TryAgain } from "components";

export const TermsOfUse: FC = memo(({ children }) => {
  const { t } = useTranslation();

  const { handle, termsOfUse, loading, userAcceptedTheTerms, error, tryAgain } =
    useTermsOfUse();

  const decodedHtml = { __html: termsOfUse?.html ?? "" };

  if (loading) return <Loader />;

  if (error)
    return (
      <TryAgain
        onClick={tryAgain}
        title={t("Something went wrong")}
        description={t(error)}
        buttonTitle={t("Try again")}
      />
    );

  if (termsOfUse?.shouldAcceptTerms === false || userAcceptedTheTerms)
    return <>{children} </>;

  return (
    <StyledDialog
      open={termsOfUse?.shouldAcceptTerms}
      content={
        <Flex column>
          <TitleContainer>
            <Text content={t("Terms of use")} />
          </TitleContainer>
          <InfoMessageContainer>
            <Text
              content={
                t(
                  "In order to use this Teams Application, you must read and accept the following terms of use"
                ) + ":"
              }
            />
          </InfoMessageContainer>
          <TosContainer column dangerouslySetInnerHTML={decodedHtml} />
        </Flex>
      }
      confirmButton={t("Accept")}
      cancelButton={t("Refuse")}
      onConfirm={() => handle(true)}
      onCancel={() => handle(false)}
    />
  );
});
