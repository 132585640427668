import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../rootReducer";
import Auth from "../../core/models/Auth";
import { AuthActionTypes, UPDATE_USERNAME, UPDATE_USER } from "./types";

export const updateUsername = (username: string): AuthActionTypes => {
  return {
    type: UPDATE_USERNAME,
    payload: { username },
  };
};

export const updateUser =
  (user: Auth) =>
  (dispatch: ThunkDispatch<RootState, undefined, AuthActionTypes>) => {
    dispatch({
      type: UPDATE_USER,
      payload: { ...user },
    });
  };
