export const UPDATE_CONFIG = "config/update";
export const UPDATE_API_CONFIG = "config/api_update";

export interface UpdateAPIAction {
  type: typeof UPDATE_API_CONFIG;
  payload: {
    api_end_point: string;
  };
}

// Update config action
export interface UpdateConfigAction {
  type: typeof UPDATE_CONFIG;
  payload: {
    companyId: number | undefined;
    front: string;
    privateKey: string;
    publicKey: string;
    jwt: string;
    welcomeMessage: string;
    firstRunMessage: string;
    api_end_point: string;
    token: string;
  };
}

//reducer interface
export interface ConfigState {
  companyId: number | undefined;
  front: string;
  privateKey: string;
  publicKey: string;
  jwt: string;
  welcomeMessage: string;
  firstRunMessage: string;
  api_end_point: string;
  token: string;
}

export type ConfigActionTypes = UpdateConfigAction | UpdateAPIAction;
