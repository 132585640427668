import { sendAsyncRequest } from "../core/utils/helpers";
import URLS from "../core/utils/urls";
import { AxiosPromise } from "axios";

/**
 * send mail to sales
 *
 * @param userId
 * @param subject
 * @param message
 * @returns
 */
const sendMail = (
  userId: number,
  subject: string,
  message: string
): AxiosPromise => {
  return sendAsyncRequest(
    "get",
    `${URLS.SALES_MAIL}/${subject}/${message}/${userId}`
  );
};

export const sales = { sendMail };
