import {
  formatRange,
  getLocalStorage,
  sendAsyncRequest,
} from "../core/utils/helpers";
import CONSTANTS from "../core/utils/constants";
import URLS from "../core/utils/urls";
import Pagination from "../core/models/Pagination";
import Document from "../core/models/Document";

/**
 * fetch documents by user ID
 *
 * @param userId
 * @param page
 * @param perPage
 * @param filter
 * @returns
 */
const fetchByUserId = (
  userId: number,
  page?: number,
  perPage?: number,
  search?: string,
  filter?: { [key: string]: number | string },
  order?: { [key: string]: string }
): Promise<{ data: Document[]; pagination: Pagination }> => {
  // authorization token
  const authorization = getLocalStorage(CONSTANTS.TOKEN) || "";
  // request params
  let params = {};
  if (filter !== undefined) params = { ...params, ...filter };
  // if per page is  undefined
  if (perPage === undefined) perPage = CONSTANTS.PER_PAGE;
  // page to range
  if (page !== undefined)
    params = { ...params, range: `${(page - 1) * perPage}-${page * perPage}` };
  // search
  if (search !== undefined) params = { ...params, search };
  // order
  if (order !== undefined) params = { ...params, ...order };
  return sendAsyncRequest(
    "get",
    URLS.USER_DOCUMENTS.replace("{id}", `${userId}`),
    authorization,
    params,
    undefined
  ).then((response) => ({
    data: response.data,
    pagination: formatRange(response.headers["content-range"]),
  }));
};

export const documents = { fetchByUserId };
