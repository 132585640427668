import CONSTANTS from "core/utils/constants";
import { getLocalStorage, sendAsyncRequest } from "core/utils/helpers";
import URLS from "core/utils/urls";
import { UserProfile } from "store/profile/types";

const fetchUserProfile = (
  userId: number
): Promise<{ data: UserProfile } | null> => {
  // authorization token
  const authorization = getLocalStorage(CONSTANTS.TOKEN) ?? "";

  return sendAsyncRequest(
    "get",
    URLS.USER_PROFILE.replace("{id}", userId.toString()),
    authorization,
    undefined,
    undefined,
    "application/json"
  )
    .then((response) => ({ data: response.data }))
    .catch((error) => {
      console.error("Error fetching user profile:", error);
      return null;
    });
};

export const userProfile = {
  fetchUserProfile,
};
