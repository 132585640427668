import React from "react";
import { Text, Flex, Button } from "@fluentui/react-northstar";
import styles from "./TryAgain.module.css";
import error from "assets/images/svg/error.svg";

interface TryAgainPropsInterface {
  title: string;
  description: string;
  onClick: () => void;
  buttonTitle: string;
  className?: string;
}

export const TryAgain = React.memo((props: TryAgainPropsInterface) => {
  const {
    title, // title
    description, // description
    buttonTitle, // button title
    onClick, // handle click try again button
    className, // image class name
  } = props;
  return (
    <Flex
      className={styles.container}
      fill
      vAlign="center"
      hAlign="center"
      column
      gap="gap.large"
    >
      <img alt={title} src={error} className={className || styles.img} />
      <Flex vAlign="center" hAlign="center" column gap="gap.smaller">
        <Text align="center" content={title} weight="bold" size="large" />
        <Text align="center" content={description} />
      </Flex>
      {onClick && (
        <Button
          onClick={onClick}
          content={buttonTitle}
          primary
          className={styles.button}
        />
      )}
    </Flex>
  );
});

export default TryAgain;
