import {
  formatRange,
  getLocalStorage,
  sendAsyncRequest,
} from "../core/utils/helpers";
import CONSTANTS from "../core/utils/constants";
import URLS from "../core/utils/urls";
import User from "../core/models/User";
import Auth from "../core/models/Auth";
import GroupUser from "../core/models/GroupUser";
import Pagination from "../core/models/Pagination";

/**
 * fetch users
 *
 * @param page
 * @param perPage
 * @param filter
 * @returns
 */
const fetch = (
  page?: number,
  perPage?: number,
  filter?: { [key: string]: number | string },
  baseUrl?: string
): Promise<{ data: Auth[]; pagination: Pagination }> => {
  // authorization token
  const authorization = getLocalStorage(CONSTANTS.TOKEN) || "";
  // request params
  let params = {};
  if (filter !== undefined) params = { ...params, ...filter };
  // if per page is  undefined
  if (perPage === undefined) perPage = CONSTANTS.PER_PAGE;
  // page to range
  if (page !== undefined)
    params = { ...params, range: `${(page - 1) * perPage}-${page * perPage}` };
  return sendAsyncRequest(
    "get",
    URLS.USERS,
    authorization,
    params,
    undefined,
    undefined,
    baseUrl
  ).then((response) => ({
    data: response.data,
    pagination: formatRange(response.headers["content-range"]),
  }));
};

/**
 * fetch users by group ID
 *
 * @param groupId
 * @param page
 * @param perPage
 * @param filter
 * @param order
 * @returns
 */
const fetchByGroupId = (
  groupId: number,
  page?: number,
  perPage?: number,
  search?: string,
  filter?: { [key: string]: number | string },
  order?: { [key: string]: string }
): Promise<{ data: GroupUser[]; pagination: Pagination }> => {
  // authorization token
  const authorization = getLocalStorage(CONSTANTS.TOKEN) || "";
  // request params
  let params = {};
  if (filter !== undefined) params = { ...params, ...filter };
  // if per page is  undefined
  if (perPage === undefined) perPage = CONSTANTS.PER_PAGE;
  // page to range
  if (page !== undefined)
    params = { ...params, range: `${(page - 1) * perPage}-${page * perPage}` };
  // search
  if (search !== undefined) params = { ...params, search };
  // order
  if (order !== undefined) params = { ...params, ...order };
  return sendAsyncRequest(
    "get",
    URLS.GROUP_USERS.replace("{id}", `${groupId}`),
    authorization,
    params,
    undefined
  ).then((response) => {
    // if users array not empty then save data in local storage
    return {
      data: response.data,
      pagination: formatRange(response.headers["content-range"]),
    };
  });
};

/**
 * create user
 *
 * @param email
 * @param firstname
 * @param lastname
 * @returns
 */
const create = (
  email: string,
  firstname: string | null,
  lastname: string | null
): Promise<User> => {
  const authorization = getLocalStorage(CONSTANTS.TOKEN) || "";
  let data = { username: email, email, role: "user", sso: true };
  //@ts-ignore
  if (firstname) data.firstname = firstname;
  //@ts-ignore
  if (lastname) data.lastname = lastname;
  return sendAsyncRequest(
    "post",
    URLS.USERS,
    authorization,
    undefined,
    data
  ).then((response) => response.data);
};

/**
 * update user last teams login date
 * @param userId
 * @returns
 */
const updateLastLoginDate = (userId: number) => {
  const authorization = getLocalStorage(CONSTANTS.TOKEN) || "";
  return sendAsyncRequest(
    "put",
    URLS.USERS_LAST_LOGIN_DATE.replace("{id}", `${userId}`),
    authorization
  );
};

export const users = { fetch, create, fetchByGroupId, updateLastLoginDate };
