import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const StyledFrameHeader = styled(Flex)({
  width: "100%",
  height: 60,
  padding: 10,
});
export const DialogSubHeader = styled(Flex)({
  height: "2.8rem",
});
export const DialogTitle = styled(Text)({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  maxWidth: "calc(100vw - 200px)",
});
