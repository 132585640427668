import { Card, Flex, Text } from "@fluentui/react-northstar";
import { memo, FC } from "react";
import { useTranslation } from "react-i18next";
import { todoItemsDateFormatter } from "core/utils/helpers";

interface Props {
  icon: JSX.Element;
  title: string;
  date?: string;
  handleClick?: () => void;
}

export const TodoCard: FC<Props> = memo(
  ({ icon, title, date, handleClick = () => {} }) => {
    const {
      i18n: { language },
    } = useTranslation();

    return (
      <Card onClick={handleClick}>
        <Flex style={{ alignItems: "center" }}>
          {icon}
          <Flex style={{ flex: 1, paddingLeft: "1rem" }} column>
            <Text className="section_title card_title_color">{title}</Text>
            {date && (
              <Text className="sub_section_title">
                {todoItemsDateFormatter(date, language)}
              </Text>
            )}
          </Flex>
        </Flex>
      </Card>
    );
  }
);
