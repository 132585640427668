import React, { FC, memo } from "react";
import { StyledDialogTitle } from "./DialogTitle.style";

interface Props {
  content: string;
}

export const DialogTitle: FC<Props> = memo(({ content }) => (
  <StyledDialogTitle content={content} color="brand" align="center" />
));
