import { Flex, Checkbox as FuiCheckbox, Text } from "@fluentui/react-northstar";
import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  label?: string;
}

export const Checkbox: FC<Props> = memo(
  ({ checked, onChange = () => {}, label = "" }) => {
    const { t } = useTranslation();

    return (
      <Flex vAlign="center">
        <FuiCheckbox
          checked={checked}
          onChange={(_, val) => {
            onChange(!!val?.checked);
          }}
        />
        {!!label && <Text content={t(label)} />}
      </Flex>
    );
  }
);
