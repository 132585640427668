import {
  CardHeader,
  Flex,
  Card as FuiCard,
  CardBody as FuiCardBody,
} from "@fluentui/react-northstar";
import styled from "styled-components";

export const Card = styled(FuiCard)({
  position: "relative",
  borderRadius: "0.5rem",
  maxHeight: "20rem",
  maxWidth: "20rem",
  minWidth: "19rem",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  padding: 0,
  flexBasis: "calc(25% - 1rem)",
  margin: " 0 0.5rem 0 0",
  transition: ".2s",
  "&:hover": {
    transform: "scale(1.065)",
  },
  "@media (max-width: 768px)": {
    flexBasis: "unset",
    margin: "0 0 1rem 0",
  },
});
export const ImageContainer = styled(CardHeader)({
  maxHeight: "12.5rem",
  height: "12.5rem",
  width: "100%",
});
export const Image = styled("img")({
  borderRadius: "0.5rem 0.5rem  0 0",
  width: "100%",
  height: "100%",
  objectFit: "cover",
});
export const CardBody = styled(FuiCardBody)({
  padding: "0 1rem",
  width: "100%",
});
export const TrainingTitleContainer = styled(Flex)({
  color: "var(--card-title)",
  fontWeight: 700,
  fontSize: "1.125rem",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
});
export const TrainingTypeContainer = styled(Flex)({
  color: "var(--card-sub-title)",
  fontSize: ".75rem",
  paddingTop: ".25rem",
});
export const CardBodyContent = styled(Flex)({
  "& div:first-child": {
    maxWidth: "15.625rem",
    flex: 1,
  },
  ".badge_container": {
    padding: "0.4rem 0",
  },
});
export const FloatingIcon = styled.div({
  position: "absolute",
  top: "1rem",
  right: "1rem",
  width: "2rem",
  height: "2rem",
  background: "white",
  borderRadius: "2rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
