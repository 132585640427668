import Auth from "../../core/models/Auth";

export const UPDATE_USERNAME = "auth/update-username";
export const UPDATE_USER = "auth/update-user";

interface Payload extends Auth {
  token: string;
}

// update username action
export interface UpdateUsername {
  type: typeof UPDATE_USERNAME;
  payload: { username: string };
}

// update user action
export interface UpdateUser {
  type: typeof UPDATE_USER;
  payload: Auth;
}

//reducer interface
export interface AuthState {
  auth: Payload;
}

export type AuthActionTypes = UpdateUsername | UpdateUser;
