import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { ReactComponent as Lock } from "assets/images/svg/lock.svg";

export const AdditionalInfoContainer = styled(Flex)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

export const AdditionalInfoTitle = styled(Text)({
  fontWeight: "700",
  fontSize: "0.75rem",
  lineHeight: "1.25rem",
  width: "100%",
  textAlign: "start",
  marginBottom: "0.5rem",
  textTransform: "uppercase",
  color: "var(--card-sub-title)",
});
export const AdditionalInfo = styled(Flex)({
  backgroundColor: "var(--color-gray-shade)",
  borderRadius: "0.5rem",
  padding: ".75rem",
  display: "flex",
  flexDirection: "column",
  minHeight: "4rem",
  justifyContent: "center",
  color: "var(--color-black)",
});
export const DataContainer = styled(Flex)({
  justifyContent: "space-between",
});
export const StepIconContainer = styled("div")({
  height: "2rem",
  width: "2rem",
  marginRight: "1rem",
});
export const NotAvailableContainer = styled("div")({
  width: "100%",
  height: "100%",
  backgroundColor: "var(--color-gray-shade)",
  borderRadius: "0.5rem",
  border: 10,
  borderColor: "var(--color-gray-shade)",
  alignItems: "center",
});
export const BoxOverlay = styled("div")({
  backgroundColor: "var(--course-card-overlay)",
  position: "absolute",
  width: "100%",
  height: "100%",
  borderRadius: "0.5rem",
  zIndex: 1,
});
export const LockIcon = styled(Lock)({
  display: "block",
  position: "absolute",
  left: "0",
  right: "0",
  top: "0",
  bottom: "0",
  margin: "auto",
  zIndex: 2,
});
