import React from "react";
import { useTranslation } from "react-i18next";
import CONFIGS from "core/utils/configs";
import styles from "./Tabs.module.css";

const Tabs = React.memo(() => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <div className={styles.logo} />
        <h1 className={styles.logoTitle}>{CONFIGS.SHORT_NAME}</h1>
      </div>
      <div id="dashboard" className={styles.tabs}>
        <span>{t("Dashboard")}</span>
      </div>
      <div id="trainings" className={styles.tabs}>
        <span>{t("My courses")}</span>
      </div>
      <div id="documents" className={styles.tabs}>
        <span>{t("My documents")}</span>
      </div>
      <div id="teams" className={styles.tabs}>
        <span>{t("My teams")}</span>
      </div>
    </div>
  );
});

export default Tabs;
