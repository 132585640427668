import CONSTANTS from "core/utils/constants";
import { getLocalStorage, sendAsyncRequest } from "core/utils/helpers";
import URLS from "core/utils/urls";
import { CompanySettings } from "store/settings/types";

const fetchSettings = (userId: number): Promise<{ data: CompanySettings }> => {
  // authorization token
  const authorization = getLocalStorage(CONSTANTS.TOKEN) ?? "";
  // request params
  let params = {};

  return sendAsyncRequest(
    "get",
    URLS.SETTINGS.replace("{id}", `${userId}`),
    authorization,
    params
  ).then((response) => ({ data: response.data }));
};

export const settings = {
  fetchSettings,
};
