import React, { FC, memo, useState } from "react";
import {
  DropdownHeader,
  DropdownOption,
  DropdownOptions,
  DropdownWrapper,
} from "./Dropdown.style";
import { Flex, Text } from "@fluentui/react-northstar";
import { ReactComponent as DownIcon } from "assets/images/svg/down.svg";

interface Props {
  items: { value: string; label: string }[];
  onChange: (value: string) => void;
  value: string;
}

export const Dropdown: FC<Props> = memo(({ items, onChange, value }) => {
  const [open, setOpen] = useState(false);
  const currentLabel = items.find((item) => value === item.value)?.label;
  const listItems = items.filter((item) => value !== item.value);

  const handleItemClick = (selectedValue: string) => {
    setOpen(false);
    onChange(selectedValue);
  };
  return (
    <DropdownWrapper>
      <DropdownHeader onClick={() => setOpen(!open)} vAlign="center">
        <Text content={currentLabel} />
        <Flex vAlign="center" style={{ padding: "1rem" }}>
          <DownIcon />
        </Flex>
      </DropdownHeader>
      {open && (
        <DropdownOptions>
          {listItems.map(({ value, label }, index) => (
            <DropdownOption key={index} onClick={() => handleItemClick(value)}>
              <Text content={label} />
            </DropdownOption>
          ))}
        </DropdownOptions>
      )}
    </DropdownWrapper>
  );
});
