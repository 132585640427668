import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import TeamsRoutes from "./routes/TeamsRoutes";
import BrowserRoutes from "./routes/BrowserRoutes";
import Tabs from "./components/Tabs/Tabs";
import { Provider as TeamsProvider, themeNames } from "@fluentui/react-teams";
import { Flex, Loader } from "@fluentui/react-northstar";
import { useInitialize } from "hooks/useInitialize";
import { initTeamsTheme } from "core/utils/helpers";
import CONSTANTS from "core/utils/constants";
import DomainSelector from "guard/DomainSelector/DomainSelector";
import { store } from "store/store";
import { logAnalyticsEvent } from "pendo";
import { EventName } from "core/models/Analytics";

const App = React.memo(() => {
  const { inTeams, i18n, theme, loading } = useInitialize();
  const [themeName, globalClass] = initTeamsTheme(theme);

  useEffect(() => {
    document.body.classList.remove(...Object.values(CONSTANTS.GLOBAL_CLASSES));
    document.body.classList.add(globalClass);
  }, [globalClass]);

  useEffect(() => {
    logAnalyticsEvent(EventName.AppStart);
  }, []);

  return loading ? null : (
    <Provider store={store}>
      <TeamsProvider themeName={themeName as themeNames} lang={i18n.language}>
        <Flex fill column className="main_container">
          <Tabs />
          <Suspense
            fallback={
              <Flex style={{ flex: 1 }} vAlign="center" hAlign="center">
                <Loader />
              </Flex>
            }
          >
            <DomainSelector>
              <>{inTeams ? <TeamsRoutes /> : <BrowserRoutes />}</>
            </DomainSelector>
          </Suspense>
        </Flex>
      </TeamsProvider>
    </Provider>
  );
});

export default App;
