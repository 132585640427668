import {
  CLEAR_COMPANY_SETTINGS_ACTION_TYPE,
  STORE_COMPANY_SETTINGS_ACTION_TYPE,
  ClearSettingsAction,
  CompanySettings,
  StoreSettingsAction,
} from "./types";

export const storeCompanySettings = (
  data: CompanySettings
): StoreSettingsAction => {
  return {
    type: STORE_COMPANY_SETTINGS_ACTION_TYPE,
    payload: { companySettings: data },
  };
};

export const clearCompanySettings = (): ClearSettingsAction => {
  return {
    type: CLEAR_COMPANY_SETTINGS_ACTION_TYPE,
    payload: {},
  };
};
