const API_URL = process.env.REACT_APP_API_URL || "http://localhost:1081";
const OVH_API_URL =
  process.env.REACT_APP_OVH_API_URL || "http://localhost:1081";

const CONFIGS = {
  API_URL,
  OVH_API_URL,
  BASE_DOMAINMAIL: process.env.REACT_APP_BASE_DOMAINMAIL || "riseup.ai",
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || "@env@",
  AES_KEY: process.env.REACT_APP_AES_KEY || "",
  AES_IV: process.env.REACT_APP_AES_IV || "",
  PRIVATE_KEY: process.env.REACT_APP_PRIVATE_KEY || "",
  SHORT_NAME: process.env.REACT_APP_SHORT_NAME || "",
  ADMIN_CENTER_APP_ID: process.env.REACT_APP_ADMIN_CENTER_APP_ID || "",
  BOT_ID: process.env.REACT_APP_BOT_ID || "",
  API_DOMAINS: [API_URL, OVH_API_URL],
};

export default CONFIGS;
