import React, { Fragment } from "react";
import { Flex, Image, Text, Button } from "@fluentui/react-northstar";
import Joyride from "react-joyride";
import styles from "./TourGuide.module.css";
import { useGetTeamsContext } from "hooks";

type Placement =
  | "top"
  | "top-start"
  | "top-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "left"
  | "left-start"
  | "left-end"
  | "right"
  | "right-start"
  | "right-end"
  | "auto"
  | "center";

interface TourGuidePropsInterface {
  stepIndex: number;
  run: boolean;
  steps: Array<{
    target: string;
    placement: Placement;
    img: string;
    title: string;
    description: string;
    primaryButton?: { title: string; onClick: () => void };
    secondaryButton?: { title: string; onClick: () => void };
    indicator?: string;
  }>;
}

export const TourGuide = React.memo(
  ({ steps, stepIndex, run }: TourGuidePropsInterface) => {
    const { showGuide } = useGetTeamsContext();

    if (!showGuide) return null;

    return (
      <Fragment>
        {
          //@ts-ignore
          <Joyride
            stepIndex={stepIndex}
            continuous
            hideCloseButton
            disableCloseOnEsc
            disableOverlayClose
            spotlightPadding={0}
            run={run}
            styles={{
              options: {
                width: 320,
                arrowColor: "var(--color-blue-sky-dark)",
              },
              tooltipFooter: {
                display: "none",
              },
              tooltip: {
                padding: 0,
              },
            }}
            steps={steps.map((e) => ({
              target: e.target,
              placement: e.placement,
              disableBeacon: true,
              content: (
                <Flex fill column className={styles.guideContainer}>
                  <Image src={e.img} className={styles.guideImg} />
                  <Flex
                    className={styles.guideTitleContainer}
                    space="between"
                    vAlign="center"
                  >
                    <Text
                      content={e.title}
                      weight="bold"
                      size="larger"
                      align="start"
                    />
                    {e.indicator && <Text content={e.indicator} disabled />}
                  </Flex>
                  <Text
                    content={e.description}
                    className={styles.guideDescription}
                  />
                  <Flex
                    fill
                    space="between"
                    vAlign="end"
                    className={styles.guideButtonContainer}
                  >
                    <div>
                      {e.secondaryButton && (
                        <Button
                          content={e.secondaryButton.title}
                          tinted
                          onClick={e.secondaryButton.onClick}
                          styles={({ theme: { siteVariables } }) => ({
                            color: siteVariables.colorScheme.brand.background,
                            ":hover": {
                              color:
                                siteVariables.colorScheme.brand.backgroundHover,
                            },
                          })}
                        />
                      )}
                    </div>
                    <div>
                      {e.primaryButton && (
                        <Button
                          content={e.primaryButton.title}
                          primary
                          onClick={e.primaryButton.onClick}
                        />
                      )}
                    </div>
                  </Flex>
                </Flex>
              ),
            }))}
          />
        }
      </Fragment>
    );
  }
);
