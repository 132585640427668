import React, { FC, memo, useState } from "react";
import TrainingModel from "core/models/Training";
import {
  Card,
  CardBody,
  CardBodyContent,
  FloatingIcon,
  Image,
  ImageContainer,
  TrainingTitleContainer,
  TrainingTypeContainer,
} from "./Card.style";
import {
  CloseIcon,
  Dialog,
  Flex,
  Text,
  Button,
} from "@fluentui/react-northstar";
import { ReactComponent as BadgeIcon } from "assets/images/svg/badge.svg";
import { getTrainingType } from "core/utils/helpers";
import { ReactComponent as FlagIcon } from "assets/images/svg/flag.svg";
import { Frame } from "components/Frame/Frame";
import styles from "./Card.module.css";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { useTranslation } from "react-i18next";
import LINKS from "core/utils/links";
import defaultTrainingIcon from "assets/images/png/training.png";
import { Image as TrainingImage } from "../Image/Image";
import { logAnalyticsEvent } from "pendo";
import { EventName } from "core/models/Analytics";

interface Props {
  training: TrainingModel;
}

export const CatalogCard: FC<Props> = memo(({ training }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const { front: subDomain, token: jwt } = useSelector(
    (state: RootState) => state.config
  );
  const isCertified = !!training.certificatesNumber;
  const isRecommended = !!training.isRecommended;
  const link =
    subDomain +
    LINKS.CATALOG_ITEM.replace("{id}", training.id.toString()).replace(
      "{token}",
      jwt
    );

  const onClose = () => setOpen(false);
  const onOpen = () => {
    logAnalyticsEvent(EventName.CatalogTrainingDetail, {
      training: training.id,
      title: training.title,
      type: getTrainingType(training.moduletype),
    });
    setOpen(true);
  };
  return (
    <>
      <Card onClick={onOpen}>
        {isRecommended && (
          <FloatingIcon>
            <FlagIcon />
          </FloatingIcon>
        )}
        <ImageContainer>
          <Image src={training.img} />
        </ImageContainer>
        <CardBody>
          <CardBodyContent>
            <Flex column>
              <TrainingTitleContainer>
                <Text content={training.title} />
              </TrainingTitleContainer>
              <TrainingTypeContainer>
                <Text content={getTrainingType(training.moduletype)} />
              </TrainingTypeContainer>
            </Flex>
            <Flex className="badge_container" hAlign="center">
              {isCertified && <BadgeIcon />}
            </Flex>
          </CardBodyContent>
        </CardBody>
      </Card>
      <Dialog
        className={styles.dialog}
        open={open}
        onOpen={onOpen}
        onCancel={onClose}
        content={
          <>
            <Flex
              vAlign="center"
              space="between"
              className={styles.dialogHeader}
            >
              <Flex vAlign="center" gap="gap.small" fill>
                <TrainingImage
                  alt={t("Training")}
                  errorSrc={defaultTrainingIcon}
                  src={training.img || defaultTrainingIcon}
                  className={styles.dialogImg}
                />
                <Flex
                  fill
                  vAlign="center"
                  column
                  space="between"
                  className={styles.dialogSubHeader}
                >
                  <Text
                    content={training.title}
                    size="large"
                    weight="bold"
                    className={styles.dialogTitle}
                  />
                  <Text
                    content={
                      training.catalogexternal && training.catalogname
                        ? training.catalogname
                        : t(getTrainingType(training.moduletype))
                    }
                  />
                </Flex>
              </Flex>
              <Flex>
                <Button
                  tabIndex={-1}
                  icon={<CloseIcon />}
                  circular
                  text
                  iconOnly
                  title={t("Close")}
                  onClick={onClose}
                />
              </Flex>
            </Flex>
            <Frame
              onClose={onClose}
              src={link}
              className={styles.frame}
              title={training.title}
            />
          </>
        }
      />
    </>
  );
});
