import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import styles from "./NotFound.module.css";

const NotFound = React.memo(() => {
  // translation hooks
  const { t } = useTranslation();

  return (
    <Flex
      fill
      column
      vAlign="center"
      hAlign="center"
      className={styles.container}
    >
      <Text content={t("Oops! page not found")} className={styles.title} />
      <div>
        <Text content="4" className={styles.firstChar} />
        <Text content="0" className={styles.secondChar} />
        <Text content="4" className={styles.thirdChar} />
      </div>
      <Text
        content={t("We are sorry, but the page you looking for was not found")}
        className={styles.subTitle}
      />
    </Flex>
  );
});

export default NotFound;
