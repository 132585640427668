import { Flex, Text } from "@fluentui/react-northstar";
import React, { FC, memo } from "react";
import { ArrowContainer, Container } from "./Toggle.style";
import { useTranslation } from "react-i18next";
import { ReactComponent as Arrow } from "assets/images/svg/arrow_bottom.svg";

interface Props {
  items: { label: string; value: string }[];
  handleToggle: (value: string) => void;
  value: string;
}

export const Toggle: FC<Props> = memo(({ items, handleToggle, value }) => {
  const { t } = useTranslation();
  const CurrentItem = items.find((item) => value === item.value);
  const toggleItem = items.find((item) => value !== item.value);

  return (
    <Container onClick={() => handleToggle(toggleItem?.value ?? "")}>
      <Text content={t(CurrentItem?.label ?? "")} />
      <ArrowContainer
        rotate={CurrentItem?.value !== "desc" ? "180deg" : "0deg"}
      >
        <Arrow />
      </ArrowContainer>
    </Container>
  );
});
