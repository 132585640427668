import React from "react";
import { Button, Dialog, Flex } from "@fluentui/react-northstar";
import { SettingsIcon } from "@fluentui/react-icons-northstar";
import Company from "core/models/Company";
import { SelectableItem as CompanyItem } from "../SelectableItem/SelectableItem";
import styles from "./SelectCompany.module.css";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { ConfirmButton } from "components/Button/ConfirmButton/ConfirmButton";
import { DialogTitle } from "components/DialogTitle/DialogTitle";
import { DialogFooter } from "components/DialogFooter/DialogFooter";

interface SelectCompanyPropsInterface {
  data: Company[];
  value?: number;
  onSubmit: () => void;
  onChange: (company: number) => void;
  dialogTitle: string;
  submitBtnTitle: string;
  triggerBtnTitle: string;
  disabled: boolean;
  open?: boolean;
  closeOnOutsideClick?: boolean;
  children: React.ReactNode;
}

export const SelectCompany = React.memo(
  ({
    data, // list of companies
    value, // selected company
    onSubmit, // handle submit company
    onChange, // handle change company
    dialogTitle, // dialog title
    submitBtnTitle, // submit button title
    triggerBtnTitle, // trigger button title
    disabled, // disable submit button
    children,
    ...rest
  }: SelectCompanyPropsInterface) => {
    const { companyId } = useSelector((state: RootState) => state.config);
    if (companyId) return <>{children} </>;

    return data.length > 1 ? (
      <Dialog
        style={{ paddingBottom: 0 }}
        content={
          <Flex column>
            <DialogTitle content={dialogTitle} />
            <Flex column>
              {data.map((e) => (
                <CompanyItem
                  key={e.id}
                  onClick={() => onChange(e.id)}
                  value={value === e.id}
                  title={e.name}
                />
              ))}
            </Flex>
            <DialogFooter>
              <ConfirmButton
                content={submitBtnTitle}
                disabled={disabled}
                onClick={onSubmit}
              />
            </DialogFooter>
          </Flex>
        }
        trigger={
          <Button
            className={styles.tirggerButton}
            circular
            icon={<SettingsIcon />}
            primary
            title={triggerBtnTitle}
          />
        }
        {...rest}
      />
    ) : null;
  }
);
