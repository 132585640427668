import CONSTANTS from "core/utils/constants";
import { getLocalStorage, sendAsyncRequest } from "../core/utils/helpers";
import URLS from "../core/utils/urls";

/**
 * get api token
 * @param publicKey
 * @param privateKey
 * @returns
 */
export const eventLog = (
  userId: number,
  data: { [key: string]: string | number }
): Promise<{ [key: string]: string }> => {
  // gets auth token
  const authorization = getLocalStorage(CONSTANTS.TOKEN) || "";
  // set grant type
  return sendAsyncRequest(
    "post",
    URLS.EVENT_LOG.replace("{id}", userId.toString()),
    authorization,
    undefined,
    data,
    "application/json"
  ).then((response) => response?.data);
};
