import { Button } from "@fluentui/react-northstar";
import styled from "styled-components";

export const StyledConfirmButton = styled(Button)({
  border: "none",
  padding: "1.5rem",
  borderRadius: "0.5rem",
  "&:disabled": {
    backgroundColor: "var(--color-blue-med)",
    opacity: "0.5",
  },
});
