const LINKS = {
  MANAGE_GROUPS: "GroupAdministration?jwttoken={token}",
  SESSION_CALENDAR:
    "Calendar/list?jwttoken={token}&selectedDate={AAAA}-{MM}-{DD}&type=myteam&group={id}",
  INSCRIPTIONS: "Reporting?jwttoken={token}&group={id}",
  TRAININGS: "Training/view/{id}/step/{step_id}?jwttoken={token}&embed_view=1",
  TRAINING_VIEW: "Training/view/{id}?jwttoken={token}&embed_view=1",
  CATALOG: "Catalog?jwttoken={token}",
  SESSIONS:
    "Training/view/{training_id}/session/{session_id}?jwttoken={token}&embed_view=1",
  DOWNLOAD_DOCUMENT: "Document/download/",
  EVALUATIONS: "Evaluation/onHoldEvaluations?jwttoken={token}&embed_view=1",
  CATALOG_ITEM:
    "Catalog/training/{id}?jwttoken={token}&embed_view=1&breadcrumb=false",
};

export default LINKS;
