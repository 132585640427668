import { FC, memo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Skeleton,
} from "@fluentui/react-northstar";

export const SkeletonCard: FC = memo(() => {
  return (
    <Card>
      <Skeleton animation="pulse">
        <CardHeader>
          <Skeleton.Line />
        </CardHeader>
        <CardBody style={{ paddingTop: "20px" }}>
          <Skeleton.Line />
        </CardBody>
      </Skeleton>
    </Card>
  );
});
