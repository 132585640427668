import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as SearchIcon } from "assets/images/svg/search.svg";
import { Container, IconContainer, StyledInput } from "./SearchBar.style";

interface Props {
  handleSearch: (val: string) => void;
  value: string;
}

export const SearchBar: FC<Props> = memo(
  ({ value, handleSearch = () => {} }) => {
    const { t } = useTranslation();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      handleSearch(e.target.value);
    };

    return (
      <Container className="searchbar_container">
        <IconContainer>
          <SearchIcon className="search_icon" />
        </IconContainer>
        <StyledInput
          value={value}
          onChange={handleChange}
          placeholder={t("Search")}
        />
      </Container>
    );
  }
);
