import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import configReducer from "./config/reducer";
import settingsReducer from "./settings/reducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  config: configReducer,
  settings: settingsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
