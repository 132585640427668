import { Flex, Input } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Container = styled(Flex)({
  width: "100%",
  height: "100%",
  alignItems: "center",
});
export const Price = styled(Input)({
  "& input": {
    borderRadius: "2rem",
    width: "3rem",
    display: "flex",
    textAlign: "center",
    "&:disabled": {
      color: "black",
    },
  },
});
