import { EmptyCard, Loader, SelectCompany, TryAgain } from "components";
import { useCompanies } from "hooks/useCompanies";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import welcomeImage from "assets/images/png/welcome.png";

export const CompanySelector: FC = ({ children }) => {
  const { t, i18n } = useTranslation();
  const {
    data,
    onSubmit,
    companyId,
    onChange,
    disabled,
    closeOnOutsideClick,
    open,
    loading,
    error,
    fetch,
  } = useCompanies();

  if (loading) return <Loader />;
  if (!loading && !data.length)
    return (
      <EmptyCard
        img={welcomeImage}
        title={t(
          "You need to have an account on a Rise Up platform in order to use our application."
        )}
        link={{
          title: t(
            "Please contact the support team to complete the registration process."
          ),
          onClick: () => {
            window.open(
              `https://helpdesk.riseup.ai/hc/${i18n.language}`,
              "_blank"
            );
          },
        }}
      />
    );
  if (error)
    return (
      <TryAgain
        onClick={fetch}
        title={t("Something went wrong")}
        description={t(error)}
        buttonTitle={t("Try again")}
      />
    );
  return (
    <SelectCompany
      dialogTitle={t("Choose your platform")}
      submitBtnTitle={t("Validate")}
      triggerBtnTitle={t("Change your platform")}
      data={data}
      value={companyId}
      onChange={onChange}
      onSubmit={onSubmit}
      disabled={disabled}
      {...(closeOnOutsideClick ? { open, closeOnOutsideClick } : {})}
    >
      {children}
    </SelectCompany>
  );
};
