import React, { useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";

import { HashRouter, Routes, Route, useLocation } from "react-router-dom";
import PATHS from "core/utils/paths";
import { useTranslation } from "react-i18next";
import { updateUsername } from "store/auth/actions";
import { useDispatch } from "react-redux";
import {
  CompanySelector,
  TermsOfUse,
  WithAuthentication,
  WithCompanySettings,
} from "guard";
import { logAnalyticsEvent } from "pendo";
import { EventName } from "core/models/Analytics";
import { setLocale } from "locales";

const RouteChangeLogger: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (!location || !location?.pathname) return;

    const pathName = location.pathname?.substring(1);
    logAnalyticsEvent(pathName as EventName);
  }, [location]);

  return null;
};

const Dashboard = React.lazy(() => import("pages/Dashboard/Dashboard"));
const Catalog = React.lazy(() => import("pages/Catalog/Catalog"));
const Trainings = React.lazy(() => import("pages/Trainings/Trainings"));
const Documents = React.lazy(() => import("pages/Documents/Documents"));
const Groups = React.lazy(() => import("pages/Groups/Groups"));
const NotFound = React.lazy(() => import("pages/NotFound/NotFound"));

const TeamsRoutes: React.FC = React.memo(() => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const [contextUser, setContextUser] =
    useState<microsoftTeams.app.UserInfo | null>(null);

  useEffect(() => {
    microsoftTeams.app
      .getContext()
      .then((context: microsoftTeams.app.Context) => {
        if (!contextUser) setContextUser(context.user || null);
        else if (contextUser && contextUser.id !== context.user?.id)
          setContextUser(context.user || null);

        if (context.app.locale) setLocale(context.app.locale, "culture");

        const username = context?.user?.loginHint ?? "";
        dispatch(updateUsername(username));
      })
      .catch((error) => {
        console.error("Error fetching context:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routes = [
    { path: PATHS.DASHBOARD, element: <Dashboard /> },
    { path: PATHS.CATALOG, element: <Catalog /> },
    { path: PATHS.TRAININGS, element: <Trainings /> },
    { path: PATHS.DOCUMENTS, element: <Documents /> },
    { path: PATHS.GROUPS, element: <Groups /> },
    { path: "*", element: <NotFound /> },
  ];

  return (
    <CompanySelector>
      <WithAuthentication>
        <WithCompanySettings>
          <HashRouter>
            <RouteChangeLogger />
            <Routes>
              {React.Children.toArray(
                routes
                  .map((route) => ({
                    ...route,
                    element: <TermsOfUse>{route.element} </TermsOfUse>,
                  }))
                  .map((route) => <Route {...route} />)
              )}
            </Routes>
          </HashRouter>
        </WithCompanySettings>
      </WithAuthentication>
    </CompanySelector>
  );
});

export default TeamsRoutes;
