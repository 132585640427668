import React, { FC, memo } from "react";
import { StyledConfirmButton } from "./ConfirmButton.style";

interface Props {
  content: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const ConfirmButton: FC<Props> = memo(
  ({ content, disabled = false, onClick = () => {} }) => (
    <StyledConfirmButton
      disabled={disabled}
      primary
      onClick={onClick}
      content={content}
    />
  )
);
