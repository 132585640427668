import { Flex } from "@fluentui/react-northstar";
import styled from "styled-components";

export const DropdownWrapper = styled.div({
  position: "relative",
  display: "inline-block",
  color: "var(--filters-font-color)",
});

export const DropdownHeader = styled(Flex)({
  cursor: "pointer",
  padding: 10,
  border: "none",
  background: "transparent",
  fontWeight: 700,
  textTransform: "uppercase",
});

export const DropdownOptions = styled.ul({
  position: "absolute",
  top: "100%",
  left: 0,
  width: "100%",
  margin: 0,
  backgroundColor: "var(--filters-bg-color)",
  listStyleType: "none",
  zIndex: 100,

  border: "1px solid #eaecf2",
  boxShadow: "none",
  padding: ".25rem",
});

export const DropdownOption = styled.li({
  cursor: "pointer",
  fontSize: 11,
  fontWeight: 700,
  padding: 12,
});
