import React from "react";
import { Button, Image, Text } from "@fluentui/react-northstar";
import styles from "./EmptyCard.module.css";

interface EmptyCardPropsInterface {
  img: string;
  title: string;
  description?: string;
  link?: {
    title: string;
    onClick: () => void;
  };
  primaryButton?: {
    title: string;
    onClick: () => void;
  };
  secondaryButton?: {
    title: string;
    onClick: () => void;
  };
}

export const EmptyCard = React.memo((props: EmptyCardPropsInterface) => {
  const { img, title, description, link, primaryButton, secondaryButton } =
    props;
  return (
    <div className={styles.container}>
      <Image alt={title} circular src={img} className={styles.img} />
      <Text
        content={title}
        align="center"
        size="large"
        weight="bold"
        className={styles.title}
      />
      {description && (
        <Text
          content={description}
          align="center"
          size="small"
          className={styles.description}
        />
      )}
      {link && (
        <Text
          content={link.title}
          align="center"
          size="small"
          className={styles.link}
          onClick={link.onClick}
        />
      )}
      {primaryButton && (
        <Button
          onClick={primaryButton.onClick}
          content={primaryButton.title}
          primary
          className={styles.button}
        />
      )}
      {secondaryButton && (
        <Button
          onClick={secondaryButton.onClick}
          content={secondaryButton.title}
          className={styles.button}
        />
      )}
    </div>
  );
});
