export const STORE_COMPANY_SETTINGS_ACTION_TYPE = "company-settings/store";
export const CLEAR_COMPANY_SETTINGS_ACTION_TYPE = "company-settings/clear";

export interface StoreSettingsAction {
  type: typeof STORE_COMPANY_SETTINGS_ACTION_TYPE;
  payload: {
    companySettings: CompanySettings;
  };
}

export interface ClearSettingsAction {
  type: typeof CLEAR_COMPANY_SETTINGS_ACTION_TYPE;
  payload: {};
}

//reducer interface
export type CompanySettings = {
  catalog?: {
    activated: boolean;
    idcompany?: number;
    activated_catalog?: number;
    activated_resourcelibrary?: number;
    activated_beta?: number;
    activated_favorites?: number;
    activated_recents?: number;
    activated_recommended_trainings?: number;
    activated_recents_period_type?: string;
    activated_recents_period_units?: number;
    activated_recents_period_custom?: null;
    activated_training_recommended?: number;
    activated_followed_by_my_group?: number;
    novalidation?: number;
    nointernalvalidation?: null;
    noaudience?: null;
    norating?: number;
    activatedpartnerlimit?: number;
    trainingrequestuser?: number;
    proposesessions?: number;
    proposesessionsmandatory?: number;
    last_activation_date?: string | null;
    noregister?: number;
    filtertrainingtype?: number;
    filtersessiondateandlocation?: number;
    catalog_share_training?: number;
    createddate?: string;
    updateddate?: string | null;
    disableddate?: string | null;
    disabled?: number;
    training_request_from_catalog_detail?: number;
    hide_marketplace_for_partners?: number;
    flow_enabled?: number;
    flow_activated_perimeter?: string;
    activated_list_order?: "alphabetical" | "chronological";
  };
  communication?: {
    activated: boolean;
    id?: number;
    idcompany?: number;
    communityboard?: number;
    communitymainboardaccess?: number;
    communitydirectmessagesaccess?: number;
    forum?: number;
    learningfeed?: number;
    answer_learningfeed?: number;
    comment_module?: number;
    personalnotes?: number;
    allow_user_create_topic?: number;
    createddate?: string;
    updateddate?: string | null;
    disableddate?: string | null;
    disabled?: number;
    reaction_main_boards?: number;
  };
  mytrainings?: {
    activated: boolean;
    id?: number;
    idcompany?: number;
    redirectpaths?: number;
    authorizearchivetrainings?: number;
    comment_module?: number;
    personalnotes?: number;
    afk?: number;
    validated?: number;
    forward?: number;
    videoautoplay?: number;
    freezetrainingstatistic?: number;
    authorizeunregisterwithoutcomment?: number;
    filtertrainingtype?: number;
    activated_skills?: number;
    activated_bankofquestions?: number;
    activated_assessments?: number;
    choose_criteria?: number;
    assessor_comment?: number;
    criteria_and_comment?: number;
    usesessionrealtimespent?: number;
    createddate?: string;
    updateddate?: string | null;
    disableddate?: string | null;
    disabled?: number;
    trainingrequestadmin?: number;
    enable_min_time?: number;
    card_statistics_hidden?: number;
    time_until_afk?: number;
    time_spent_outside_of_the_training_before_kick?: number;
    training_order?: "alphabetical" | "chronological";
  };
  objectives?: {
    activated: boolean;
    id?: number;
    idcompany?: number;
    activated_gamification_token?: number;
    activated_gamification?: number;
    activate_objectives_instructions?: number;
    objectives_instructions?: null;
    createddate?: string;
    updateddate?: string | null;
    disableddate?: string | null;
    disabled?: number;
  };
};

export type CompanySettingsActionTypes =
  | StoreSettingsAction
  | ClearSettingsAction;
