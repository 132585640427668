import { Flex } from "@fluentui/react-northstar";
import styled from "styled-components";

interface ArrowProps {
  rotate: boolean;
}

export const Container = styled(Flex)({
  "& span": {
    color: "var(--color-gray-darker)",
    cursor: "pointer",
    fontSize: ".75rem",
    fontWeight: 600,
  },
});
export const ArrowContainer = styled(Flex)<ArrowProps>(({ rotate }) => ({
  paddingLeft: "0.625rem",
  "& svg": {
    transform: `rotate(${rotate})`,
  },
}));
