import { Flex } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Container = styled(Flex)({
  width: "100%  !important",
  alignItems: "center",
  "& div,input": {
    width: "100%",
  },
  "& input": {
    borderRadius: "1rem",
    width: "100%",
    "@media (max-width: 600px)": {
      "&": {
        flexBasis: "100%",
      },
    },
  },
});
