import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import { ChevronDownIcon } from "@fluentui/react-icons-northstar";
import styles from "./Table.module.css";

interface TableHeaderCellPropsInterface {
  title: string;
  orderDir?: "asc" | "desc" | null;
  onClick?: () => void;
  disabled?: boolean;
  pointer?: boolean;
}

export const TableHeaderCell = React.memo(
  ({
    title, // cell title
    orderDir, // order direction
    onClick, // handle cell click
    disabled, // is cell disabled
    pointer, // is cursor type pointer
  }: TableHeaderCellPropsInterface) => {
    return (
      <th
        className={styles.th}
        onClick={disabled ? undefined : onClick}
        style={{ cursor: pointer ? "pointer" : "default" }}
      >
        <Flex fill space="between" vAlign="center" inline>
          <Text content={title} align="start" size="medium" weight="bold" />
          {!disabled &&
            (orderDir === "asc" ? (
              <ChevronDownIcon />
            ) : (
              <ChevronDownIcon className={styles.rotate} />
            ))}
        </Flex>
      </th>
    );
  }
);

export default TableHeaderCell;
