import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import { AES, enc } from "crypto-js";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import Pagination from "../models/Pagination";
import CONSTANTS from "./constants";
import CONFIGS from "./configs";
import LINKS from "./links";
import { themeNames } from "@fluentui/react-teams";
import DocumentModel from "core/models/Document";
import { t } from "i18next";
import { store } from "store/store";
import { HostClientType } from "@microsoft/teams-js";

/**
 * decrypt cipher text
 * @param ciphertext
 * @param key
 * @returns
 */
export const decrypt = (ciphertext: string, key: string): string => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, key);
  return bytes.toString(CryptoJS.enc.Utf8);
};

/**
 * send async request
 * @param method "post" | "get" | "put" | "patch" | "delete"
 * @param baseURL
 * @param url
 * @param authorization set header authorization
 * @param params
 * @param data
 * @param content_type
 * @returns
 */
export const sendAsyncRequest = (
  method: "post" | "get" | "put" | "patch" | "delete",
  url: string,
  authorization?: string,
  params?: { [key: string]: string | number },
  data?: { [key: string]: any },
  contentType?: string,
  baseUrl?: string
): AxiosPromise<any> => {
  const baseURL = baseUrl ?? store.getState().config.api_end_point;
  let config: AxiosRequestConfig = {
    baseURL,
    timeout: 20000,
    url,
    method,
    headers: {
      Accept: "application/json",
      "content-type": contentType ? contentType : "application/json",
    },
  };
  if (params) config.params = params;
  if (data) config.data = data;
  if (authorization) config.headers = { ...config.headers, authorization };
  return axios(config);
};

/**
 * format date (jj/mm/aaaa)
 * @param date
 * @returns
 */
export const formatDate = (date: string): string => {
  if (date === "") return date;
  const jj = date.split(" ")[0].split("-")[2];
  const mm = date.split(" ")[0].split("-")[1];
  const aaaa = date.split(" ")[0].split("-")[0];
  return `${jj}/${mm}/${aaaa}`;
};

/**
 * check numeric
 *
 * @param {string} value
 *
 * @returns {boolean}
 */
export const isNumeric = (value: string) => {
  return /^\d+$/.test(value);
};

/**
 * format range
 * @param range
 * @returns
 */
export const formatRange = (range: string): Pagination => {
  try {
    const total = range.split("/")[1];
    const current = range.split("/")[0].split("-")[0];
    return {
      lastPage: Math.ceil(parseInt(total) / CONSTANTS.PER_PAGE),
      currentPage: parseInt(current) / CONSTANTS.PER_PAGE + 1,
      total: parseInt(total),
    };
  } catch (error) {
    return {
      lastPage: 1,
      currentPage: 1,
      total: 0,
    };
  }
};

/**
 * encrypte local storage
 *
 * @param key
 * @param data
 */
export const setLocalStorage = (key: string, data: string) => {
  if (CONFIGS.PRIVATE_KEY) {
    const cryptedData = AES.encrypt(data, CONFIGS.PRIVATE_KEY).toString();
    localStorage.setItem(key, cryptedData);
  }
};
// Possible values for theme: 'default', 'light', 'dark' and 'contrast'
export function initTeamsTheme(theme: string | undefined) {
  switch (theme) {
    case "dark":
      return [themeNames.Dark, CONSTANTS.GLOBAL_CLASSES.DARK];
    case "contrast":
      return [themeNames.HighContrast, CONSTANTS.GLOBAL_CLASSES.CONTRAST];
    default:
      return [themeNames.Default, CONSTANTS.GLOBAL_CLASSES.DEFAULT];
  }
}
/**
 * decrypte local storage
 *
 * @param key
 * @returns
 */
export const getLocalStorage = (key: string) => {
  try {
    const cryptedData = localStorage.getItem(key);
    if (cryptedData === null || !CONFIGS.PRIVATE_KEY) return null;
    const data = AES.decrypt(cryptedData, CONFIGS.PRIVATE_KEY).toString(
      enc.Utf8
    );
    return data;
  } catch (error) {
    return null;
  }
};

/**
 * encrypte cookies
 *
 * @param key
 * @param expires
 * @param data
 */
export const setCookies = (key: string, expires: Date, data: string) => {
  const cryptedData = AES.encrypt(data, CONFIGS.PRIVATE_KEY).toString();

  Cookies.set(key, cryptedData, {
    expires,
    sameSite: "None",
    secure: true,
  });
};

/**
 * decrypte cookies
 *
 * @param key
 * @returns
 */
export const getCookies = (key: string) => {
  try {
    const cryptedData = Cookies.get(key);
    if (!cryptedData) return null;
    const data = AES.decrypt(cryptedData, CONFIGS.PRIVATE_KEY).toString(
      enc.Utf8
    );
    return data;
  } catch (error) {
    return null;
  }
};

/**
 * get user display name
 * @param firstName
 * @param lastName
 * @param userName
 * @returns
 */
export const getUserDisplayName = (
  firstName: string | null,
  lastName: string | null,
  userName: string
): string => {
  if (!firstName && !lastName) return userName;
  else {
    if (firstName && lastName) return `${firstName} ${lastName}`;
    else {
      if (firstName) return firstName;
      else {
        if (lastName) return lastName;
      }
    }
  }
  return userName;
};

/**
 * format education duration
 *
 * @param eduduration
 * @returns
 */
export const formatEduduration = (eduduration: number) => {
  const mins = eduduration % 60;
  const hours = Math.floor(eduduration / 60);
  return `${hours}.${mins}`;
};

/**
 * check if the date is tomorrow
 * @param val
 * @returns
 */
export const isTomorrow = (val: string) => {
  const today = new Date();
  const date = new Date(val);
  const diff = Math.abs(date.getTime() - today.getTime());
  const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
  return diffDays === 1;
};

/**
 * get company subdomaine
 * @param name
 * @param custom
 * @returns
 */
export function getSubdomain(name: string, custom: number): string {
  if (
    CONFIGS.ENVIRONMENT === "development" ||
    CONFIGS.ENVIRONMENT === "development-ovh"
  ) {
    return `https://dev.${CONFIGS.BASE_DOMAINMAIL}/`;
  }
  if (CONFIGS.ENVIRONMENT === "integration") {
    return `https://integration.${CONFIGS.BASE_DOMAINMAIL}/`;
  }
  if (
    CONFIGS.ENVIRONMENT === "preproduction" ||
    CONFIGS.ENVIRONMENT === "preproduction-ovh"
  ) {
    return `https://preprod.${CONFIGS.BASE_DOMAINMAIL}/`;
  }
  if (CONFIGS.ENVIRONMENT === "@env@") {
    return "https://localhost/";
  }
  let subdomainStr = `https://${name}`;
  if (custom && custom > 0) {
    subdomainStr += "/";
  } else {
    subdomainStr += `.${CONFIGS.BASE_DOMAINMAIL}/`;
  }
  return subdomainStr;
}

/**
 * get training type name
 * @param type
 * @returns
 */
export const getTrainingType = (type: string): string => {
  switch (type) {
    case "online":
      return "E-learning";
    case "physical":
      return "Face-to-face";
    default:
      return "Blended";
  }
};
/**
 *
 * @param subDomain // sub domain for the app
 * @param token user token
 * @param id course id
 * @param latest_step_id last step of the course id
 * @returns
 */
export function handleFrameSrc(
  subDomain: string,
  token: string,
  id: number,
  latest_step_id: number | null
) {
  let frameSrc = `${subDomain}${LINKS.TRAININGS.replace(
    "{id}",
    `${id}`
  ).replace("{token}", token)}`;
  if (latest_step_id) {
    frameSrc = frameSrc.replace("{step_id}", latest_step_id.toString());
  } else {
    frameSrc = frameSrc.replace("/step/{step_id}", "");
  }
  return frameSrc;
}
/**
 *
 * @param subDomain
 * @param iddocumentqueue
 * @param token
 * @param type
 * @returns
 */
export const getFilePreviewUrl = (
  subDomain: string,
  { iddocumentqueue, token, type }: DocumentModel
) => {
  return `${subDomain}Document/display?iddocumentqueue=${iddocumentqueue}&token=${token}&documentType=${type}`;
};

export const todoItemsDateFormatter = (
  dateString: string,
  language: string
) => {
  const date = new Date(dateString);

  const day = date.getDate();
  const year = date.getFullYear();

  let month = date.toLocaleString(language, { month: "long" });
  if (language === "en") {
    month = month.charAt(0).toUpperCase() + month.slice(1);
  }
  if (month.length > 3) {
    month = month.slice(0, 3) + ".";
  }

  let hours: number | string = date.getHours();
  let minutes: number | string = date.getMinutes();

  // Add a leading zero to minutes if needed
  minutes = minutes < 10 ? "0" + minutes : minutes;
  hours = hours < 10 ? "0" + hours : hours;

  return `${day} ${month} ${year} - ${hours}:${minutes}`;
};

export const formatCourseCardDuration = (duration: number) => {
  const mins = duration % 60;
  const hours = Math.floor(duration / 60);
  if (mins) {
    return `${hours}h${mins < 10 ? "0" + mins : mins}`;
  }
  return `${hours} ${t(hours === 1 ? "hour" : "hours")}`;
};

export const formatDuration = (seconds: number): string => {
  if (!seconds || seconds < 60) {
    return `${seconds}s`;
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m${remainingSeconds}s`;
  } else {
    const hours = Math.floor(seconds / 3600);
    const remainingMinutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h${remainingMinutes}m`;
  }
};

export const getDevice = (): HostClientType => {
  return (
    (getLocalStorage(CONSTANTS.LOCAL_STORAGE.TEAMS_DEVICE) as HostClientType) ||
    HostClientType.desktop
  );
};

export const capitalizeFirstLetter = (string: string) => {
  if (!string) return;
  return string.replace(/^./, string[0].toUpperCase());
};
