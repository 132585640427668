import { Flex } from "@fluentui/react-northstar";
import styled from "styled-components";

export const StyledInput = styled("input")({
  borderRadius: "0 1.25rem 1.25rem 0 ",
  height: "2.5rem",
  display: "flex",
  alignItems: "center",
  borderTop: "1px solid var(--filters-border-color)",
  borderRight: "1px solid var(--filters-border-color)",
  borderBottom: "1px solid var(--filters-border-color)",
  background: "var(--filters-bg-color)",
  borderLeft: "none",
  color: "var(--filters-font-color)",
  flex: "1",
  "&:focus": {
    outline: "none",
  },
});
export const IconContainer = styled("div")({
  height: "2.5rem",
  display: "flex",
  alignItems: "center",
  borderRadius: "1.25rem  0 0 1.25rem",
  paddingLeft: "1.25rem",
  paddingRight: "0.75rem",
  background: "var(--filters-bg-color)",
  borderLeft: "1px solid var(--filters-border-color)",
  borderTop: "1px solid var(--filters-border-color)",
  borderBottom: "1px solid var(--filters-border-color)",
});
export const Container = styled(Flex)({
  width: "100%",
  position: "relative",
});
