import React from "react";
import styles from "./Loader.module.css";
import { Loader as FUILoader } from "@fluentui/react-northstar";

export const Loader = React.memo(() => {
  return (
    <div className={styles.loaderContainer}>
      <FUILoader />
    </div>
  );
});
