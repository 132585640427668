import { Flex } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Container = styled.div({
  padding: "1rem  0 1.5rem 0",
  position: "relative",
  maxHeight: "21rem",
  "& .slick-list": {
    height: "100%  !important",
  },
  "& ul ": {
    "& li ": {
      "& button::before": {
        color: "#F26A5B",
        "& .slick-active": {
          color: "#F26A5B !important",
        },
      },
    },
  },
  ".slick-dots li.slick-active button:before": {
    color: "#F26A5B",
  },
});
export const Arrow = styled(Flex)({
  position: "absolute",
  top: "43%",
  borderRadius: "50%",
  width: "2rem",
  height: "2rem",
  display: "flex !important",
  justifyContent: "center",
  alignItems: "center",
  background: "white",
  cursor: "pointer",

  "&::before": {
    display: "none",
  },
  "&:hover": {
    background: "white",
  },
});
export const NextArrow = styled(Arrow)({
  right: "1rem",
  transform: " scale(1.5)",
});
export const PrevArrow = styled(Arrow)({
  left: "1rem",
  zIndex: "100",
  transform: "rotate(180deg) scale(1.5)",
});
