import {
  formatRange,
  getLocalStorage,
  sendAsyncRequest,
} from "../core/utils/helpers";
import CONSTANTS from "../core/utils/constants";
import URLS from "../core/utils/urls";
import ObjectiveLevel from "../core/models/ObjectiveLevel";
import Pagination from "../core/models/Pagination";

/**
 * fetch objective levels by user id
 *
 * @param userId
 * @param page
 * @param perPage
 * @param filter
 * @returns
 */
const fetchByUserId = (
  userId: number,
  page?: number,
  perPage?: number,
  filter?: { [key: string]: number | string }
): Promise<{
  data: ObjectiveLevel[];
  pagination: Pagination;
}> => {
  // authorization token
  const authorization = getLocalStorage(CONSTANTS.TOKEN) || "";
  // request params
  let params = {};
  if (filter !== undefined) params = { ...params, ...filter };
  // if per page is  undefined
  if (perPage === undefined) perPage = CONSTANTS.PER_PAGE;
  // page to range
  if (page !== undefined)
    params = { ...params, range: `${(page - 1) * perPage}-${page * perPage}` };
  return sendAsyncRequest(
    "get",
    URLS.OBJECTIVES_LEVELS.replace("{id}", `${userId}`),
    authorization,
    params,
    undefined
  ).then((response) => ({
    data: response.data,
    pagination: formatRange(response.headers["content-range"]),
  }));
};

export const objectivelevels = { fetchByUserId };
