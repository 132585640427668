import Cookies from "js-cookie";

/**
 * get cookie value
 * @param key
 * @returns
 */
export const getCookie = (key: string): string | undefined => {
  return Cookies.get(key);
};

/**
 * set cookie
 * @param key
 * @param value
 * @param expires
 */
export const setCookie = (
  key: string,
  value: string,
  expires: Date,
  domain: string
) => {
  const cookieStored = Cookies.set(key, value, {
    expires,
    sameSite: "None",
    secure: true,
    domain,
  });
  return cookieStored;
};
