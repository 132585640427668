const PATHS = {
  NOT_FOUND: "/not-found",
  HOME: "/home/",
  TRAININGS: "/trainings",
  CATALOG: "/catalog",
  DOCUMENTS: "/documents",
  GROUPS: "/groups",
  DASHBOARD: "/dashboard",
  WELCOME: "/welcome/",
  GROUP_MANAGMENT: "/:id/managment",
  GROUP_USERS: "/:id/users",
  GROUP_TRAININGS_IN_PROGRESS: "/:id/trainings/progress",
  GROUP_SESSION_PLANING: "/:id/sessions/plannig",
};

export default PATHS;
