import { FC, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { APIS } from "apis";
import { setLocalStorage } from "core/utils/helpers";
import CONSTANTS from "core/utils/constants";
import { updateUser } from "store/auth/actions";
import { RootState } from "store/rootReducer";
import TryAgain from "components/TryAgain/TryAgain";
import i18next from "i18next";
import { setLocale } from "locales/index";
import { Loader } from "components";
import { initAnalytics, logAnalyticsEvent } from "pendo";
import { EventName } from "core/models/Analytics";

export const WithAuthentication: FC = ({ children }) => {
  // loading state
  const [loading, setLoading] = useState<boolean>(true);
  // error message
  const [error, setError] = useState<string>("");
  // get public and private keys from redux
  const { publicKey, privateKey } = useSelector(
    (store: RootState) => store.config
  );
  // get auth user name state from redux
  const authName = useSelector((store: RootState) => store.auth.auth.username);
  // disptach hooks
  const dispatch = useDispatch();
  // translation hooks
  const { t } = useTranslation();

  // authenticate user
  const authentication = useCallback(
    async (username, companyPublicKey, companyPrivateKey) => {
      // start loader
      setLoading(true);
      try {
        // get api token
        const token = await APIS.auth.authentication(
          companyPublicKey,
          companyPrivateKey
        );
        // check if there was an error while getting api token
        if (token.access_token && token.token_type) {
          setLocalStorage(
            CONSTANTS.TOKEN,
            `${token.token_type} ${token.access_token}`
          );
        } else {
          token.error_description
            ? setError(token.error_description)
            : setError(i18next.t("Something went wrong"));
          setLoading(false);
          return;
        }
        // get list of users filtred by usename
        const users = await APIS.users.fetch(undefined, undefined, {
          username,
        });
        // check if there is a user with user name belongs to selected company
        if (users.data.length) {
          const user = users.data[0];
          // select first user
          dispatch(updateUser(user));

          // get user profile info
          const userInfo = await APIS.userProfile.fetchUserProfile(user.id);

          if (!!userInfo && !!userInfo?.data) {
            const analytics = userInfo?.data?.analytics;
            initAnalytics(analytics);
          } else initAnalytics();

          if (userInfo?.data.languageId) {
            setLocale(userInfo?.data.languageId);
          }

          // update user last login date
          APIS.users.updateLastLoginDate(users.data[0].id);

          // log authentication event
          logAnalyticsEvent(EventName.LoginSuccess);

          // reset error
          setError("");
        } else {
          // log authentication failed event
          logAnalyticsEvent(EventName.LoginFailure);
          setError("This user does not exist");
        }
        // stop loader
        setLoading(false);
      } catch (err) {
        // log authentication failed event
        logAnalyticsEvent(EventName.LoginFailure);
        //@ts-ignore
        setError(err.message);
        setLoading(false);
      }
    },
    []
  );

  // authenticate user
  useEffect(() => {
    if (authName && privateKey && publicKey) {
      authentication(authName, publicKey, privateKey);
    }
  }, [authName, privateKey, publicKey]);

  if (loading) return <Loader />;

  if (error)
    return (
      <TryAgain
        onClick={() => authentication(authName, publicKey, privateKey)}
        title={t("Something went wrong")}
        description={t(error)}
        buttonTitle={t("Try again")}
      />
    );

  return <>{children} </>;
};
