import {
  getCookies,
  getLocalStorage,
  sendAsyncRequest,
  setCookies,
} from "core/utils/helpers";
import CONSTANTS from "core/utils/constants";
import URLS from "core/utils/urls";

/**
 * fetch configs
 *
 * @returns
 */
const fetch = (): Promise<{
  welcome_message: string;
  first_run_message: string;
}> => {
  const authorization = getLocalStorage(CONSTANTS.TOKEN) || "";
  // check if config already saved in cookies , if it is the case then return config from cookies
  const cookiesData = getCookies(
    `${CONSTANTS.COOKIES.CONFIGS}/${authorization}`
  );
  if (cookiesData) return JSON.parse(cookiesData);
  // get token
  return sendAsyncRequest("get", URLS.CONFIGS, authorization).then(
    (response) => {
      // format data
      const data = {
        welcome_message: response.data.welcome_message,
        first_run_message: response.data.first_run_message,
      };
      // get expiration date
      const expires = new Date(
        new Date().getTime() + CONSTANTS.COOKIES_DURATION
      );
      // save data in cookies
      setCookies(
        `${CONSTANTS.COOKIES.CONFIGS}/${authorization}`,
        expires,
        JSON.stringify(data)
      );
      return data;
    }
  );
};

export const configs = { fetch };
