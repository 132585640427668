import { Button, CloseIcon, Flex, Text } from "@fluentui/react-northstar";
import { Image } from "components/Image/Image";
import { FC, memo } from "react";
import {
  DialogSubHeader,
  DialogTitle,
  StyledFrameHeader,
} from "./FrameHeader.style";
import defaultTrainingIcon from "assets/images/png/training.png";
import TrainingModel from "core/models/Training";
import { useTranslation } from "react-i18next";
import { getTrainingType } from "core/utils/helpers";
import styles from "./FrameHeader.module.css";

interface Props {
  handleClose: () => void;
  course?: TrainingModel;
}

export const FrameHeader: FC<Props> = memo(({ handleClose, course }) => {
  const { t } = useTranslation();
  return (
    <StyledFrameHeader vAlign="center" space="between">
      <Flex vAlign="center" gap="gap.small" fill>
        {course && (
          <Image
            alt={t("Training")}
            errorSrc={defaultTrainingIcon}
            src={course.img || defaultTrainingIcon}
            className={styles.dialogImg}
          />
        )}
        {course && (
          <DialogSubHeader fill vAlign="center" column space="between">
            <DialogTitle content={course.title} size="large" weight="bold" />
            <Text
              content={
                course.catalogexternal && course.catalogname
                  ? course.catalogname
                  : t(getTrainingType(course.moduletype))
              }
            />
          </DialogSubHeader>
        )}{" "}
      </Flex>
      <Flex>
        <Button
          tabIndex={-1}
          icon={<CloseIcon />}
          circular
          text
          iconOnly
          title={t("Close")}
          onClick={handleClose}
        />
      </Flex>
    </StyledFrameHeader>
  );
});
