import React, { memo } from "react";

import { Skeleton, Card, Flex, CardBody } from "@fluentui/react-northstar";
import {
  CardBodyContent,
  ImageContainer,
  TrainingTitleContainer,
  TrainingTypeContainer,
} from "components/CatalogCard/Card.style";

export const LoadingCard = memo(() => {
  return (
    <Card>
      <ImageContainer>
        <Skeleton.Shape width="100%" height="100%" />
      </ImageContainer>
      <CardBody>
        <CardBodyContent>
          <Flex column>
            <TrainingTitleContainer>
              <Skeleton.Shape width="100%" height="1.5rem" />
            </TrainingTitleContainer>
            <TrainingTypeContainer>
              <Skeleton.Shape width="80%" height="1.5rem" />
            </TrainingTypeContainer>
          </Flex>
        </CardBodyContent>
      </CardBody>
    </Card>
  );
});
