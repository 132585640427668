import React from "react";
import {
  CircleIcon,
  CheckmarkCircleIcon,
} from "@fluentui/react-icons-northstar";
import { StyledButton } from "./SelectableItem.style";

interface CompanyItemPropsInterface {
  value: boolean;
  onClick: () => void;
  title: string;
}

export const SelectableItem = React.memo(
  ({
    value, // is selected or not
    onClick, // handle click on item
    title, // item title
  }: CompanyItemPropsInterface) => {
    return (
      <StyledButton
        icon={value ? <CheckmarkCircleIcon outline /> : <CircleIcon outline />}
        content={title}
        iconPosition="before"
        onClick={onClick}
        primary={value}
        tinted={!value}
        fluid
      />
    );
  }
);
