import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import languages from "locales/languages.json";

type TranslationResource = {
  [culture: string]: {
    translation: any;
  };
};
const resources: TranslationResource = {};

languages.forEach((language) => {
  const filepath = `./${language.translation_file_locale}.json`;
  let translation = null;
  try {
    translation = require(`${filepath}`);
  } catch (err) {
    console.error("Translation file not found", err);
    return;
  }

  resources[language.translation_file_locale] = {
    translation,
  };
});

i18n.use(initReactI18next).init({
  resources,
  lng: "fr",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export function strings(name: string, params = {}) {
  return i18n.t(name, params);
}

type FindLocaleBy = "id" | "culture";

export function setLocale(
  locale: number | string,
  findBy: FindLocaleBy = "id"
) {
  if (!locale) {
    console.warn("No locale provided!");
  }

  const language = languages.find(({ id, culture }) => {
    if (findBy === "id") return id === locale;

    return culture.toLowerCase() === locale.toString().toLowerCase();
  });
  if (!language) {
    console.warn("No locale found!", locale);
    return;
  }
  i18n.changeLanguage(language.translation_file_locale, (error: string) => {
    if (error) {
      console.warn("Error in changing language to:", language.culture, error);
    } else {
      console.info("Language set to:", language.culture);
    }
  });
}
