import { Flex } from "@fluentui/react-northstar";
import {
  Dialog,
  DialogFooter,
  DialogTitle,
  Loader,
  SelectableItem,
} from "components";
import { ConfirmButton } from "components/Button/ConfirmButton/ConfirmButton";
import CONFIGS from "core/utils/configs";
import useDomainSelector from "hooks/useDomainSelector";
import { FC, useState, Children } from "react";
import { useTranslation } from "react-i18next";

const domainsData = [
  {
    title: "Rise Up Europe (AWS)",
    value: CONFIGS.API_URL,
  },
  {
    title: "Rise Up France (OVH/Azure)",
    value: CONFIGS.OVH_API_URL,
  },
];

const DomainSelector: FC = ({ children }) => {
  const { t } = useTranslation();
  const { domain, selectDomain, loading } = useDomainSelector();
  const [selectedDomain, setSelectedDomain] = useState<string>();

  if (loading) return <Loader />;

  if (domain) return <> {children} </>;

  return (
    <Dialog
      open={true}
      content={
        <Flex column>
          <Flex vAlign="center" hAlign="center">
            <DialogTitle content={t("Choose your region")} />
          </Flex>
          <Flex column>
            {Children.toArray(
              domainsData.map(({ title, value }) => (
                <SelectableItem
                  title={title}
                  value={value === selectedDomain}
                  onClick={() => setSelectedDomain(value)}
                />
              ))
            )}
          </Flex>
          <DialogFooter>
            <ConfirmButton
              content={t("Validate")}
              onClick={() => selectedDomain && selectDomain(selectedDomain)}
              disabled={!selectedDomain}
            />
          </DialogFooter>
        </Flex>
      }
    />
  );
};

export default DomainSelector;
