import styled from "styled-components";
import { Dropdown as FuiDropdown } from "@fluentui/react-northstar";

export const Dropdown = styled(FuiDropdown)({
  "@media (max-width: 768px)": {
    "& .ui-dropdown__container": {
      width: "100%",
    },
  },
  "& .ui-dropdown__container": {
    borderRadius: "1.25rem",
  },
  "& .ui-dropdown__toggle-indicator": {
    top: "unset",
  },
});
