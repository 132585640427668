import styled from "styled-components";
import { Dialog, Flex } from "@fluentui/react-northstar";

export const StyledDialog = styled(Dialog)({});

export const TitleContainer = styled(Flex)({
  fontWeight: "700",
  fontSize: "1.125rem",
  lineHeight: "1.5rem",
  paddingBottom: "2rem",
});

export const InfoMessageContainer = styled(Flex)({
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: "1.5rem",
  paddingBottom: "1.5rem",
});
export const TosContainer = styled(Flex)({
  background: "var(--background-color)",
  borderRadius: "0.5rem",
  maxHeight: "400px",
  overflowY: "scroll",
  padding: "1rem",
  marginBottom: "2rem",
  color: "var(--font-color)",
  "& > h5": {
    color: "var(--font-color) !important",
  },
});
