import {
  CLEAR_COMPANY_SETTINGS_ACTION_TYPE,
  STORE_COMPANY_SETTINGS_ACTION_TYPE,
  CompanySettings,
  CompanySettingsActionTypes,
} from "./types";

const INITIAL_STATE: CompanySettings = {
  catalog: {
    activated: false,
  },
  communication: {
    activated: false,
  },
  mytrainings: {
    activated: false,
  },
  objectives: {
    activated: false,
  },
};

const reducer = (
  state = INITIAL_STATE,
  action: CompanySettingsActionTypes
): CompanySettings => {
  switch (action.type) {
    case STORE_COMPANY_SETTINGS_ACTION_TYPE:
      return {
        ...state,
        ...action.payload.companySettings,
      };
    case CLEAR_COMPANY_SETTINGS_ACTION_TYPE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default reducer;
