import { Frame } from "components/Frame/Frame";
import { FrameHeader } from "components/FrameHeader/FrameHeader";
import TrainingModel from "core/models/Training";
import { FC, Fragment, memo } from "react";
import styles from "./FrameLayout.module.css";

interface Props {
  course?: TrainingModel;
  handleClose: () => void;
  src: string;
}

export const FrameLayout: FC<Props> = memo(
  ({ course = undefined, handleClose, src }) => {
    return (
      <Fragment>
        <FrameHeader course={course} handleClose={handleClose} />
        <Frame
          onClose={handleClose}
          src={src}
          className={styles.frame}
          title={course?.title ?? ""}
        />
      </Fragment>
    );
  }
);
