import { getLocalStorage, sendAsyncRequest } from "../core/utils/helpers";
import CONSTANTS from "../core/utils/constants";
import URLS from "../core/utils/urls";

const fetchUpcomingCourses = (userId: number): Promise<any> => {
  // authorization token
  const authorization = getLocalStorage(CONSTANTS.TOKEN) || "";

  return sendAsyncRequest(
    "get",
    URLS.UPCOMING_SESSIONS.replace("{id}", `${userId}`),
    authorization
  ).then((response) => ({
    data: response.data,
  }));
};

const fetchOnHoldEvaluations = (userId: number): Promise<any> => {
  // authorization token
  const authorization = getLocalStorage(CONSTANTS.TOKEN) || "";
  return sendAsyncRequest(
    "get",
    URLS.ON_HOLD_EVALUATIONS.replace("{id}", `${userId}`),
    authorization
  ).then((response) => ({
    data: response.data,
  }));
};

const fetchTodos = (userId: number): Promise<any> => {
  // authorization token
  const authorization = getLocalStorage(CONSTANTS.TOKEN) || "";
  return sendAsyncRequest(
    "get",
    URLS.TODOS.replace("{id}", `${userId}`),
    authorization
  ).then((response) => ({
    data: response.data,
  }));
};

export const todos = {
  fetchUpcomingCourses,
  fetchOnHoldEvaluations,
  fetchTodos,
};
