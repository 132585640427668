import React from "react";
import NotFound from "../pages/NotFound/NotFound";
import { HashRouter, Routes, Route } from "react-router-dom";

const BrowserRoutes = React.memo(() => {
  return (
    <HashRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </HashRouter>
  );
});

export default BrowserRoutes;
