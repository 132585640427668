import { Dialog } from "@fluentui/react-northstar";
import styled from "styled-components";

export const StyledFrameDialog = styled(Dialog)({
  width: "90%",
  height: "90vh",
  overflowX: "scroll",
  display: "block",
  padding: 0,
  "&>div:nth-child(1)": {
    margin: 0,
    height: "100%",
    overflowY: "hidden",
  },
});
