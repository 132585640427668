import { AxiosResponse } from "axios";
import { convertCategory } from "core/models/Category";
import PaginationModel from "core/models/Pagination";
import TrainingModel from "core/models/Training";
import CONSTANTS from "core/utils/constants";
import {
  formatRange,
  getLocalStorage,
  sendAsyncRequest,
} from "core/utils/helpers";
import URLS from "core/utils/urls";
import { FiltersData, FiltersDataDTO } from "shared/interfaces";

const fetchCatalogTrainings = (
  userId: number,
  url: string,
  page: number,
  perPage?: number,
  search?: string,
  filter?: { [key: string]: number | string },
  order?: { [key: string]: string }
): Promise<{ data: TrainingModel[]; pagination: PaginationModel }> => {
  // authorization token
  const authorization = getLocalStorage(CONSTANTS.TOKEN) ?? "";
  // request params
  let params = {};
  if (filter !== undefined) params = { ...params, ...filter };
  // if per page is  undefined
  if (perPage === undefined) perPage = CONSTANTS.PER_PAGE;
  // page to range
  if (page !== undefined)
    params = { ...params, range: `${(page - 1) * perPage}-${perPage}` };
  // search
  if (search !== undefined) params = { ...params, search };
  // order
  if (order !== undefined) params = { ...params, ...order };
  return sendAsyncRequest(
    "get",
    url.replace("{id}", `${userId}`),
    authorization,
    params
  ).then((response) => {
    return {
      data: response.data,
      pagination: formatRange(response.headers["content-range"]),
    };
  });
};

const fetchFilterData = (userId: number): Promise<FiltersData> => {
  const authorization = getLocalStorage(CONSTANTS.TOKEN) ?? "";

  return sendAsyncRequest(
    "get",
    URLS.CATALOG_FILTERS.replace("{id}", `${userId}`),
    authorization
  ).then((response: AxiosResponse<FiltersDataDTO>) => {
    return {
      ...response.data,
      categories: response.data.categories.map((category) =>
        convertCategory(category)
      ),
    };
  });
};

export const catalog = {
  fetchCatalogTrainings,
  fetchFilterData,
};
