import React, { FC, memo } from "react";
import { ReactComponent as DocumentIcon } from "assets/images/svg/training_steps/document.svg";
import { ReactComponent as ExamIcon } from "assets/images/svg/training_steps/exam.svg";
import { ReactComponent as FileuploadIcon } from "assets/images/svg/training_steps/fileupload.svg";
import { ReactComponent as IframeIcon } from "assets/images/svg/training_steps/iframe.svg";
import { ReactComponent as QuizIcon } from "assets/images/svg/training_steps/quiz.svg";
import { ReactComponent as RandomquizIcon } from "assets/images/svg/training_steps/randomquiz.svg";
import { ReactComponent as ScormIcon } from "assets/images/svg/training_steps/scorm.svg";
import { ReactComponent as ScreenCastIcon } from "assets/images/svg/training_steps/screencast.svg";
import { ReactComponent as FaceToFaceIcon } from "assets/images/svg/training_steps/session-face-to-face.svg";
import { ReactComponent as VirtualIcon } from "assets/images/svg/training_steps/session-virtual.svg";
import { ReactComponent as SurveyIcon } from "assets/images/svg/training_steps/survey.svg";
import { ReactComponent as TextIcon } from "assets/images/svg/training_steps/text.svg";
import { ReactComponent as VideoIcon } from "assets/images/svg/training_steps/video.svg";
import { ReactComponent as WebcastIcon } from "assets/images/svg/training_steps/webcast.svg";
import h5p from "assets/images/svg/training_steps/h5p.png";
import { Image } from "@fluentui/react-northstar";

interface Props {
  stepType: string;
}

export const StepIcon: FC<Props> = memo(({ stepType }) => {
  const stepTypeToIcon: {
    [type: string]: JSX.Element;
  } = {
    document: <DocumentIcon />,
    exam: <ExamIcon />,
    fileupload: <FileuploadIcon />,
    iframe: <IframeIcon />,
    quiz: <QuizIcon />,
    randomquiz: <RandomquizIcon />,
    scorm: <ScormIcon />,
    screencast: <ScreenCastIcon />,
    "session-face-to-face": <FaceToFaceIcon />,
    "session-virtual": <VirtualIcon />,
    survey: <SurveyIcon />,
    text: <TextIcon />,
    video: <VideoIcon />,
    webcast: <WebcastIcon />,
    h5p: <Image src={h5p} />,
  };
  return stepTypeToIcon[stepType] ?? null;
});
