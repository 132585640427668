import { Flex } from "@fluentui/react-northstar";
import styled from "styled-components";

export const StyledRoot = styled(Flex)({
  borderBottom: "1px solid var(--border-color)",
  height: "7.75rem",
  padding: "1.5rem",
  color: "var(--card-title)",
  "& span": {
    lineHeight: "1.625rem",
  },
});
