import React from "react";
import { DateRange } from "shared/interfaces";
import { Container } from "./DateRangePicker.style";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Input } from "@fluentui/react-northstar";
interface Props {
  handleDateRange: (dateRange: DateRange) => void;
  dateRange?: DateRange;
}

export const DateRangeInput: React.FC<Props> = ({
  handleDateRange,
  dateRange,
}) => {
  const value =
    dateRange?.startDate && dateRange?.endDate
      ? new Date(dateRange.startDate).toLocaleDateString() +
        " - " +
        new Date(dateRange.endDate).toLocaleDateString()
      : "";

  const handleApply = (e: any) => {
    const [start, end] = e.target.value.split("-");
    handleDateRange({ startDate: start, endDate: end });
  };
  return (
    <Container>
      <DateRangePicker onApply={handleApply}>
        <Input value={value} defaultValue="" />
      </DateRangePicker>
    </Container>
  );
};
