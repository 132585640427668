import { FC, memo } from "react";
import { StyledFrameDialog } from "./FrameDialog.style";

interface Props {
  content: JSX.Element;
  trigger?: JSX.Element;
  open: boolean;
  onOpen?: () => void;
  onCancel: () => void;
}

export const FrameDialog: FC<Props> = memo(
  ({ content, open, onOpen = () => {}, onCancel, trigger }) => {
    return (
      <StyledFrameDialog
        content={content}
        open={open}
        onOpen={onOpen}
        onCancel={onCancel}
        trigger={trigger}
      />
    );
  }
);
