import React, { FC, memo } from "react";
import TrainingModel from "core/models/Training";
import PaginationModel from "core/models/Pagination";
import { CatalogCard } from "components/CatalogCard/Card";
import Slider from "react-slick";
import { defaultSettings } from "./Carousel.settings";
import { NextArrow, Container, PrevArrow } from "./Carousel.style";
import { ReactComponent as ChevronRight } from "assets/images/svg/chevron-right.svg";

interface Props {
  trainings: {
    data: TrainingModel[];
    pagination: PaginationModel;
  };
}

function SampleNextArrow(props: any) {
  return (
    <NextArrow {...props}>
      <ChevronRight />
    </NextArrow>
  );
}
function SamplePrevArrow(props: any) {
  return (
    <PrevArrow {...props}>
      <ChevronRight />
    </PrevArrow>
  );
}

export const Carousel: FC<Props> = memo(({ trainings }) => {
  const settings = {
    ...defaultSettings,
    slidesToShow: trainings.data.length > 3 ? 3 : trainings.data.length,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Container>
      <Slider {...settings}>
        {trainings.data.map((item) => (
          <CatalogCard training={item} key={item.id} />
        ))}
      </Slider>
    </Container>
  );
});
