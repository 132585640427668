import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CONSTANTS from "../core/utils/constants";
import {
  getLocalStorage,
  getSubdomain,
  setLocalStorage,
} from "../core/utils/helpers";
import { APIS } from "../apis";
import Company from "../core/models/Company";
import Pagination from "../core/models/Pagination";
import { RootState } from "../store/rootReducer";
import { updateConfig } from "../store/config/actions";

export const useCompanies = () => {
  // loader state
  const [loading, setLoading] = useState<boolean>(true);
  // error message state
  const [error, setError] = useState<string>("");
  // selected platform (company)
  const [companyId, setCompanyId] = useState<undefined | number>();
  // list of companies
  const [companies, setCompanies] = useState<Company[]>([]);
  // pagination params
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    lastPage: 1,
    total: 0,
  });
  // dispatch hooks
  const dispatch = useDispatch();
  // get auth user name from redux
  const authUsername = useSelector(
    (store: RootState) => store.auth.auth.username
  );
  // get config state from redux
  const config = useSelector((store: RootState) => store.config);
  // handle submit platform
  const onSubmit = useCallback(() => {
    // save company in local storage
    setLocalStorage(authUsername + CONSTANTS.PLATFORM, `${companyId}`);
    if (config.companyId !== undefined) {
      // reload screen
      window.location.reload();
    } else {
      const company = companies.find((e) => e.id === companyId);
      if (company) dispatch(updateConfig(company));
    }
  }, [authUsername, companyId, config.companyId, companies, dispatch]);
  // handle change selected company
  const onChange = useCallback((value: number) => {
    setCompanyId(value);
  }, []);
  // fetch user companies
  const fetch = useCallback(async () => {
    // start loader
    setLoading(true);
    try {
      const token = getLocalStorage(CONSTANTS.MICROSOFT_GRAPH_TOKEN) ?? "";
      const response = await APIS.companies.fetch(1, 50, {
        username: authUsername,
        token,
      });
      setCompanies(response.data);
      setPagination(response.pagination);
      // if user doesn't belong to any platform show error message
      if (response.data.length === 0) {
        setError("This user does not exist");
      } else {
        // if user belong to only one platform => auto select platform
        if (response.data.length === 1) {
          const company = response.data[0];
          setCompanyId(company.id);
          dispatch(updateConfig(company));
        } else {
          //TODO
          const localStorageSelectedCompanyId = getLocalStorage(
            authUsername + CONSTANTS.PLATFORM
          );
          if (localStorageSelectedCompanyId) {
            const localStorageSelectedCompany = response.data.find(
              (e) => e.id === +localStorageSelectedCompanyId
            );
            if (localStorageSelectedCompany) {
              setCompanyId(localStorageSelectedCompany.id);
              dispatch(updateConfig(localStorageSelectedCompany));
            }
          }
        }
      }
      // reset error
      setError("");
    } catch (err) {
      //@ts-ignore
      setError(err.message || "Something went wrong");
    }
    // stop loader
    setLoading(false);
  }, [authUsername]);
  // fetch companies
  useEffect(() => {
    if (authUsername) fetch();
  }, [authUsername]);

  return {
    loading,
    fetch,
    error,
    data: companies,
    onSubmit,
    companyId,
    onChange,
    disabled: config.companyId === companyId,
    currentPage: pagination.currentPage,
    lastPage: pagination.lastPage,
    closeOnOutsideClick: config.companyId === undefined,
    open: !!(companies.length && config.companyId === undefined && !loading),
  };
};
