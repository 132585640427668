export interface CategoryDTO {
  id: number;
  name: string;
  idparent: number;
}

export interface Category {
  id: number;
  name: string;
  parentId: number;
}

export const convertCategory = (category: CategoryDTO): Category => {
  return {
    id: category.id,
    name: category.name,
    parentId: category.idparent,
  };
};
