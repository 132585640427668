import produce from "immer";
import {
  AuthActionTypes,
  AuthState,
  UPDATE_USERNAME,
  UPDATE_USER,
} from "./types";

const INITIAL_STATE: AuthState = {
  auth: {
    avatar: "",
    creationdate: "",
    email: "",
    firstname: "",
    id: 0,
    idpartner: null,
    language: "",
    timezone: "",
    lastlogindate: "",
    lastname: "",
    modificationdate: "",
    phonenumber: "",
    rhid: "",
    role: "",
    sso: true,
    state: "",
    suspenddate: null,
    type: "",
    username: "",
    token: "",
    groups: [],
  },
};

const reducer = (state = INITIAL_STATE, action: AuthActionTypes): AuthState =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_USERNAME:
        draft.auth = { ...state.auth, ...action.payload };
        break;
      case UPDATE_USER:
        draft.auth = { ...state.auth, ...action.payload };
        break;
    }
  });
export default reducer;
