import CONSTANTS from "core/utils/constants";
import { getLocalStorage, sendAsyncRequest } from "core/utils/helpers";
import URLS from "core/utils/urls";
import { HandledTOS, TOS } from "shared/interfaces";

export const checkTermsOfUse = (userId: number): Promise<TOS> => {
  // authorization token
  const authorization = getLocalStorage(CONSTANTS.TOKEN) ?? "";
  // request params
  return sendAsyncRequest(
    "get",
    URLS.TOS.replace("{id}", `${userId}`),
    authorization
  ).then((response) => response.data);
};

export const handleTermsOfUse = (
  userId: number,
  userAccepted: boolean
): Promise<HandledTOS> => {
  // authorization token
  const authorization = getLocalStorage(CONSTANTS.TOKEN) ?? "";
  // request params
  return sendAsyncRequest(
    "post",
    URLS.TOS.replace("{id}", `${userId}`),
    authorization,
    undefined,
    {
      accept: userAccepted,
    }
  ).then((response) => response.data);
};
